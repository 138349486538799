import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { hide, modelSelctor } from 'services/modal/slice'

export const ModalWrapper = props => {
  const { onClose, size, title, children, name, setModalRequestSucceed, titleFontSize } = props
  const model = useSelector(modelSelctor)
  const dispatch = useDispatch()
  const closeHandler = () => {
    onClose && onClose()
    dispatch(hide(name))
    setModalRequestSucceed && setModalRequestSucceed(false)
  }
  return (
    <Modal show={model[name]?.show} onHide={closeHandler} keyboard={true} size={size}>
      <Modal.Header closeButton={title ? true : false}>
        <Modal.Title>
          <div style={{ fontSize: titleFontSize }}> {title} </div>
        </Modal.Title>
      </Modal.Header>
      {children}
    </Modal>
  )
}
