import { get as apiGet, post } from 'api'

export const getTrigger = async id => apiGet(`/ApprovalFlow/triggers/${id}`)
export const getAllTrigger = async tableState => apiGet('/ApprovalFlow/triggers/', tableState)

export const addTrigger = async values => post('/ApprovalFlow/triggers', values)
export const deleteTriggerItem = async id => post(`/ApprovalFlow/triggers/${id}/delete`)
export const toggleTriggerEnabled = async id => apiGet(`/ApprovalFlow/triggers/${id}/toggleEnabled`)

export const getCompanyDocumentTypes = async () => apiGet('/Company/GetDocumentTypes')
