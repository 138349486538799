import React, { useEffect, useState } from 'react'
import { Alert } from 'reactstrap'

import { t } from 'i18n/translation'

export const AlertMessage = ({ icon, message, error, onClose, color, errorBody }) => {
  const showAlert = !!message || !!error
  const [visible, setVisible] = useState(showAlert)
  useEffect(() => {
    setVisible(!!showAlert)
  }, [showAlert])

  const handleToggle = () => {
    onClose && onClose()
    setVisible(false)
  }

  const errorMessage =
    error && ((error.errorCode && t(`errorCode.${error.errorCode}`)) || error.message || t('common.error'))

  const alertColor = color || (error && 'danger') || (message && 'info') || null
  return (
    <Alert color={alertColor} isOpen={visible} toggle={onClose && handleToggle}>
      <span>{icon}</span>
      {errorBody ?? <span>{error ? errorMessage : message}</span>}
    </Alert>
  )
}

export const ErrorPage = ({ message, onClose }) => <AlertMessage message={message} onClose={onClose} />
