import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { hide } from '../modal/slice'

import * as api from './api'

const initialModel = {
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  email: '',
  username: '',
  isLocked: false,
  existingUserFirstName: '',
  existingUserLastName: '',
  isListLoading: false,
}

export const getAll = createAsyncThunk(
  'user/GET_ALL',
  async ({ tableState = { page: 1, sizePerPage: 100, sortField: 'userName', sortOrder: 'asc' } }, thunkAPI) => {
    const { data, error } = await api.getAll(tableState)
    if (error) return thunkAPI.rejectWithValue(error)
    return data
  },
)

export const get = createAsyncThunk('user/GET', async (id, thunkAPI) => {
  const { data, error } = await api.get(id)
  if (error) thunkAPI.rejectWithValue(error)
  return data
})

export const create = createAsyncThunk('user/CREATE', async (payload, thunkAPI) => {
  const { values, modal, tableState } = payload

  const { data, error } = await api.create(values)
  if (error) return thunkAPI.rejectWithValue(error)

  thunkAPI.dispatch(getAll({ tableState, shouldLoad: true }))
  thunkAPI.dispatch(hide(modal))
  return data
})

export const update = createAsyncThunk('user/UPDATE', async (payload, thunkAPI) => {
  const { values, modal, tableState } = payload
  const { data, error } = await api.update(values)
  if (error) return thunkAPI.rejectWithValue(error)

  thunkAPI.dispatch(getAll({ tableState, shouldLoad: true }))
  thunkAPI.dispatch(hide(modal))

  return data
})

export const resetPassword = createAsyncThunk('user/RESET_PASSWORD', async (payload, thunkAPI) => {
  const { values, modal } = payload
  const { data, error } = await api.resetPassword(values)
  if (error) return thunkAPI.rejectWithValue(error)

  thunkAPI.dispatch(hide(modal))

  return data
})

export const getAllRoles = createAsyncThunk('user/GET_ALL_ROLES', async (payload, thunkAPI) => {
  const { data, error } = await api.getAllRoles()
  if (error) return thunkAPI.rejectWithValue(error)

  return data
})

export const saveUserRoles = createAsyncThunk('user/SAVE_USER_ROLES', async (payload, thunkAPI) => {
  const { values, modal, tableState } = payload
  const { data, error } = await api.saveUserRoles(values)
  if (error) return thunkAPI.rejectWithValue(error)

  thunkAPI.dispatch(hide(modal))
  thunkAPI.dispatch(getAll({ tableState, shouldLoad: true }))

  return data
})

export const validateAccountCreation = createAsyncThunk('user/VALIDATE_ACCOUNT_CREATION', async (payload, thunkAPI) => {
  const { data, error } = await api.validateAccountCreation({ email: payload })
  if (error) return thunkAPI.rejectWithValue(error)

  return data
})

export const changePassword = createAsyncThunk('user/CHANGE_PASSWORD', async ({ values, modal }, thunkAPI) => {
  const { data, error } = await api.changePassword(values)
  if (error) return thunkAPI.rejectWithValue(error)
  thunkAPI.dispatch(hide(modal))
  return data
})

export const slice = createSlice({
  name: 'user',
  initialState: {
    list: [],
    totalSize: 0,
    isListLoading: false,
    roles: [],
    isRoleListLoading: false,
    model: initialModel,
    isModelLoading: false,
    error: null,
    canCreateUser: '',
    userModel: null,
    isUpdating: false,
  },
  reducers: {
    clearError: state => {
      state.error = null
    },
    validateAccountCreationClear: state => {
      state.canCreateUser = ''
      state.userModel = null
    },
    clearModel: state => {
      state.model = initialModel
    },
    clearList: state => {
      state.list = []
      //state.totalSize = 0
    },
  },
  extraReducers: builder => [
    builder
      .addCase(getAll.pending, (state, action) => {
        state.isListLoading = action.meta.arg.shouldLoad
        state.error = null
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isListLoading = false
        state.list = action.payload.data
        state.totalSize = action.payload.totalCount
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isListLoading = false
        state.error = action.error
      })
      .addCase(get.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      })
      .addCase(create.pending, state => {
        state.isUpdating = true
        state.error = null
      })
      .addCase(create.fulfilled, state => {
        state.isUpdating = false
      })
      .addCase(create.rejected, (state, action) => {
        state.isUpdating = false
        state.error = action.error
      })
      .addCase(update.pending, state => {
        state.isUpdating = true
        state.error = null
      })
      .addCase(update.fulfilled, state => {
        state.isUpdating = false
      })
      .addCase(update.rejected, (state, action) => {
        state.isUpdating = false
        state.error = action.error
      })
      .addCase(resetPassword.pending, state => {
        state.isUpdating = true
        state.error = null
      })
      .addCase(resetPassword.fulfilled, state => {
        state.isUpdating = false
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isUpdating = false
        state.error = action.payload
      })
      .addCase(getAllRoles.pending, state => {
        state.isRoleListLoading = true
        state.error = null
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.isRoleListLoading = false
        state.roles = action.payload
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.isRoleListLoading = false
        state.error = action.error
      })
      .addCase(saveUserRoles.pending, state => {
        state.isUpdating = true
        state.error = null
      })
      .addCase(saveUserRoles.fulfilled, state => {
        state.isUpdating = false
      })
      .addCase(saveUserRoles.rejected, (state, action) => {
        state.isUpdating = false
        state.error = action.error
      })
      .addCase(validateAccountCreation.pending, state => {
        state.userModel = null
        state.canCreateUser = ''
      })
      .addCase(validateAccountCreation.fulfilled, (state, action) => {
        state.userModel = action.payload
        state.canCreateUser = action.payload.canCreateUserCheckStatus
      })
      .addCase(validateAccountCreation.rejected, (state, action) => {
        state.canCreateUser = ''
        state.error = action.error
      })
      .addCase(changePassword.pending, state => {
        state.isUpdating = true
        state.error = null
      })
      .addCase(changePassword.fulfilled, state => {
        state.isUpdating = false
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isUpdating = false
        state.error = action.payload
      }),
  ],
})

export const { clearError, clearModel, validateAccountCreationClear, clearList } = slice.actions

export const listSelector = state => state.user.list
export const totalSizeSelector = state => state.user.totalSize
export const errorSelector = state => state.user.error
export const modelSelector = state => state.user.model
export const isModelLoadingSelector = state => state.user.isModelLoading

export const rolesSelector = state => state.user.roles

export const isRoleListLoadingSelector = state => state.user.isRoleListLoading

export const canCreateUserSelector = state => state.user.canCreateUser

export const userModelSelector = state => state.user.userModel
export const isListLoadingSelector = state => state.user.isListLoading

export const reducer = slice.reducer
