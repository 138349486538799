import React from 'react'

const LoadingScript = ({ error }) => {
  if (error) {
    //window.location.reload(true)
  }
  return <div />
}

export default LoadingScript
