import { get as apiGet, post } from 'api'

export const get = async id => {
  return apiGet(`/sef/getSefChannelState?folderId=${id}`)
}

export const send = async values => {
  return post('/sef/send', values)
}

export const cancel = async values => {
  return post(`/sef/cancel`, values)
}

export const storno = async values => {
  return post('/sef/storno', values)
}
