import { withPrefix } from 'i18n/translation'
import React, { useEffect, useState } from 'react'
import { default as ReactSelect } from 'react-select'

const ReactMultiSelect = props => {
  const pt = withPrefix('scenes.approvalFlows')

  const [allOption, setAllOption] = useState({
    value: '*',
    key: 'selectAll',
  })
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused
        ? props.fieldError
          ? '#dc3545'
          : '#80bdff !important'
        : props.fieldError
          ? '#dc3545'
          : '#ced4da ',
      boxShadow: state.isFocused
        ? props.fieldError
          ? '0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important'
          : '0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important'
        : 'none',
      '&:hover': {},
    }),
  }
  useEffect(() => {
    const { options, value } = props
    if (options?.length !== 0 && options?.length === value?.length && allOption?.key !== 'deselectAll') {
      setAllOption({
        value: '*',
        key: 'deselectAll',
      })
    }
  }, [allOption?.key, props])

  if (props.allowSelectAll) {
    return (
      <ReactSelect
        {...props}
        isOpen={true}
        options={[{ ...allOption, label: pt(allOption.key) }, ...props.options]}
        onChange={selected => {
          if (selected !== null && selected.length > 0 && selected[selected.length - 1].value === allOption.value) {
            if (allOption.key === 'selectAll')
              setAllOption({
                value: '*',
                key: 'deselectAll',
              })
            else {
              setAllOption({
                value: '*',
                key: 'selectAll',
              })
            }
            return props.onChange(selected.length <= props.options.length ? props.options : null)
          } else {
            if (allOption.key === 'selectAll' && selected && selected.length === props.options.length)
              setAllOption({
                value: '*',
                key: 'deselectAll',
              })
            else {
              setAllOption({
                value: '*',
                key: 'selectAll',
              })
            }
            return props.onChange(selected)
          }
        }}
        isDisabled={props.disabled}
        styles={customStyles}
      />
    )
  }

  return <ReactSelect {...props} />
}

export default ReactMultiSelect
