import { get as apiGet, fileDownload, post } from 'api'
import { imageExtensions } from 'utils/constants'

export const get = async () => apiGet(`/company/getCompanySettings`, null, { checkingCookie: true })
export const update = async values => {
  const formData = new FormData()
  for (const property in values) {
    formData.append(property, values[property])
  }
  return post('/company/saveCompanySettings', formData)
}

export const getCompanyLogo = async logoFileName =>
  fileDownload('/company/logo').then(res => {
    var fileExt = logoFileName.split('.').pop()
    return window.URL.createObjectURL(new Blob([res.data], { type: imageExtensions[fileExt] }))
  })
