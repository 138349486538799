import { AlertMessage } from 'components/AlertMessage'
import React from 'react'
import { Modal } from 'react-bootstrap'

export const ModalBody = props => {
  const { error, message, children } = props
  return (
    <Modal.Body>
      <AlertMessage error={error} message={message} onClose={() => {}} />
      {children}
    </Modal.Body>
  )
}
