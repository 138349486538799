import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as api from './api'

export const getAll = createAsyncThunk('invoice/GET_ALL', async ({ tableState }, thunkAPI) => {
  const { data, error } = await api.getAll(tableState)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const get = createAsyncThunk('invoice/GET', async ({ id }, thunkAPI) => {
  const { data, error } = await api.get(id)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const getCount = createAsyncThunk('invoice/GET_COUNT', async ({ request }, thunkAPI) => {
  const { data, error } = await api.getCount(request)

  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const slice = createSlice({
  name: 'invoice',
  initialState: {
    list: [],
    totalSize: 0,
    isListLoading: false,
    model: null,
    isModelLoading: false,
    error: null,
    count: null,
  },
  reducers: {
    clearError: state => {
      state.error = null
    },
  },
  extraReducers: builder => [
    builder
      .addCase(getAll.pending, (state, action) => {
        state.isListLoading = action.payload.shouldLoad
        state.error = null
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isListLoading = false
        state.list = action.payload.list
        state.totalSize = action.payload.totalSize
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isListLoading = false
        state.error = action.error
      })

      .addCase(get.pending, state => {
        state.isModelLoading = false
        state.error = null
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      })

      .addCase(getCount.pending, state => {
        state.error = null
      })
      .addCase(getCount.fulfilled, (state, action) => {
        state.count = action.payload.count
      })
      .addCase(getCount.rejected, (state, action) => {
        state.error = action.error
      }),
  ],
})

export const { clearError } = slice.actions

export const modelSelector = state => state.invoice.model
export const isModelLoadingSelector = state => state.invoice.isModelLoading
export const listSelector = state => state.invoice.list
export const isListLoadingSelector = state => state.invoice.isListLoading
export const errorSelector = state => state.invoice.error
export const totalSizeSelector = state => state.invoice.totalSize
export const countSelector = state => state.invoice.count

export const reducer = slice.reducer
