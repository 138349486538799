import { useEffect } from 'react'
import { redirect } from 'react-router-dom'

import { routes } from 'routes'
import { isAuthorized } from './AuthorizeService'
import { roles } from './roles'

import {
  getUser,
  isAuthenticatedSelector,
  userCompaniesSelector,
  modelSelector as userSelector,
} from 'services/auth/slice'

import { useDispatch, useSelector } from 'react-redux'
import { get as getCompanyInfo, modelSelector } from 'services/settings/slice'
import { getUserRolesByCompany } from '../utils/helperMethods'

const useAuthorize = props => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const authenticated = useSelector(isAuthenticatedSelector)
  const userCompanies = useSelector(userCompaniesSelector)
  const company = useSelector(modelSelector)

  useEffect(() => {
    const userRoles = getUserRolesByCompany(userCompanies)

    if (!authenticated) {
      dispatch(getUser())
    }
    if (!company && user && userRoles && !userRoles.includes(roles.SUPERADMIN)) {
      dispatch(getCompanyInfo())
    }
  }, [authenticated, company, dispatch, user, userCompanies])

  //todo add dependenceis
  useEffect(() => {
    const userRoles = getUserRolesByCompany(userCompanies)
    if (!company && user && userRoles && !userRoles.includes(roles.SUPERADMIN)) {
      dispatch(getCompanyInfo())
    }
  })

  const userRoles = getUserRolesByCompany(userCompanies)
  const { roles: anyRoleRequired } = props || {}
  const authorized = isAuthorized(userRoles, anyRoleRequired)

  if (!authenticated) {
    if (!user) return null
    else redirect(routes.login)
  } else if (!company && user && userRoles && !userRoles.includes(roles.SUPERADMIN)) {
    // Load company only if it's not already loaded, otherwise app can enter in a deadloop
    // getCompanyInfo()
    return null
  }

  return { authorized, userRoles }
}

export default useAuthorize
