import { useFormField } from '../useFormField'

export const InputButton = allProps => {
  const { props, setFieldTouched, setFieldValue, name, label, btnText, fieldError, labelClasses, onButtonClick } =
    useFormField(allProps)
  const { disabled, showButton, onChange } = props
  const handleChange = (name, value) => {
    setFieldValue(name, value.target.value).then(() => setFieldTouched(name))
    onChange(value)
  }
  return (
    <div>
      {label && (
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
      )}
      <div className="input-group mb-3">
        <input
          autoComplete="off"
          onChange={value => handleChange(name, value)}
          type="text"
          id={name}
          disabled={disabled}
          className="form-control"
        />
        {showButton && (
          <div className="input-group-append">
            <button onClick={onButtonClick} className="btn btn-outline-secondary" type="button">
              {btnText ? btnText : 'Button'}
            </button>
          </div>
        )}
      </div>
      {fieldError && <small className="form-text text-danger">{fieldError}</small>}
    </div>
  )
}
