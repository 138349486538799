import { Link, useNavigate } from 'react-router-dom'

function ErrorElement() {
  const navigate = useNavigate()

  return (
    <div className="container pt-5">
      <section className="error-container text-center">
        <div className="error-divider">
          <h2>ooops!!</h2>
          <p className="description">SOMETHING WENT WRONG.</p>
          <p className="description">Please, check you network connection first</p>
        </div>
        <Link onClick={() => navigate(-1)}>Go Back</Link>
      </section>
    </div>
  )
}
export default ErrorElement
