import classnames from 'classnames'
import React from 'react'

import { withPrefix } from 'i18n/translation'

import blue from './images/blue.svg'

import styles from './styles.module.css'

const pt = withPrefix('components.loadingView')

export const Loader = ({ className }) => (
  <div className={classnames(styles.loader, className)}>
    {Array(12)
      .fill(0)
      .map((_, index) => (
        <div key={index} className={styles.dot} />
      ))}
    <img src={blue} alt="" className={styles.spinner2} />
  </div>
)

export const LoadingView = () => (
  <div className="container d-flex flex-column justify-content-center align-items-center min-h-100">
    <h1>{pt('title')}</h1>
    <Loader />
  </div>
)
