import React from 'react'

import 'react-datepicker/dist/react-datepicker.css'

import './styles.css'

import { AutoSuggest } from './components/AutoSuggest'
import { CheckBox } from './components/CheckBox'
import { CustomDatePicker } from './components/CustomDatePicker'
import { File } from './components/File'
import { InputButton } from './components/InputButton'
import { InputField } from './components/InputField'
import { PasswordField } from './components/PasswordField'
import { RadioButton } from './components/RadioButton'
import { RadioButtonField } from './components/RadioButtonField'
import { ReactMultiSelectField } from './components/ReactMultiSelectField'
import { ReactSelectField } from './components/ReactSelectField'
import { Select } from './components/Select'
import { Switch } from './components/Switch'
import { TextAreaField } from './components/TextAreaField'

export const FormField = allProps => {
  const { component, type } = allProps

  if (type === 'checkbox') return <CheckBox {...allProps} />

  if (type === 'radio-button') return <RadioButton {...allProps} />

  if (type === 'radio-button-field') return <RadioButtonField {...allProps} />

  if (type === 'switch') return <Switch {...allProps} />

  if (type === 'file') return <File {...allProps} />

  if (component === 'input-button') return <InputButton {...allProps} />

  if (component === 'select') return <Select {...allProps} />

  if (component === 'datepicker') return <CustomDatePicker {...allProps} />

  if (component === 'autosuggest') return <AutoSuggest {...allProps} />

  if (component === 'react-multi-select') return <ReactMultiSelectField {...allProps} />

  if (component === 'react-select') return <ReactSelectField {...allProps} />

  if (component === 'textarea') return <TextAreaField {...allProps} />

  if (type === 'password') return <PasswordField {...allProps} />

  return <InputField {...allProps} />
}
