import React from 'react'
import { useFormField } from '../useFormField'

export const TextAreaField = allProps => {
  const {
    props,
    textareastyle,
    field,
    label,
    className,
    style,
    labelClasses,
    formGroupClasses,
    name,
    fieldError,
    fromLibraries: { classnames, Field },
  } = useFormField(allProps)

  return (
    <div className={classnames('form-group', formGroupClasses, className)} style={style}>
      {/* <div className={classnames(labelClasses, 'form-check-label')}>{label}</div> */}
      {label && (
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
      )}
      <Field
        {...props}
        component="textarea"
        id={name}
        value={field.value}
        style={textareastyle}
        name={name}
        className={classnames('form-control', fieldError && 'is-invalid', className)}
      />
      {fieldError && <small className="form-text text-danger">{fieldError}</small>}
    </div>
  )
}
