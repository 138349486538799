import { get as apiGet, post } from 'api'

export const getAll = async request => apiGet('/user/getlist', request)

export const get = async id => apiGet(`/user/${id}`)

export const create = async values => post('/user/createAccount', values)

export const update = async values => post('/user/updateAccount', values)

export const deleteItem = async id => post(`/user/delete/${id}`)

export const getAllRoles = async () => apiGet('/company/GetAllRoleNames ')

export const saveUserRoles = async values => post('/user/SetAccountRoles', values)

export const changePassword = async values => post('/account/changePassword', values)

export const resetPassword = async values => post('/user/ResetPasswordForUser', values)

export const validateAccountCreation = async username => post('/user/validateAccountCreation', username)
