import ToastWrapper from 'components/Toast'
import { initI18n, setLocale } from 'i18n/translation'
import locales from 'i18n/translations/locales.json'
import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RouterProvider } from 'react-router'
import { languageSelector } from 'services/account/slice'
import { router } from '../../core/Root'

export const Layout = () => {
  useEffect(() => {
    initI18n(locales)
  }, [])

  const language = useSelector(languageSelector)
  setLocale(language)

  return (
    <Fragment>
      <div className="h-100 d-flex flex-column">
        <ToastWrapper autoClose={true} position={'top-right'} autoCloseDuration={6} />
        <RouterProvider router={router} />
      </div>
    </Fragment>
  )
}
