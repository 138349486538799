import classnames from 'classnames'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import './styles.css'

export const AuthNavLink = props => {
  const { active, to, state, children, handler, bootstrapClasses, isAuthorized } = props
  if (isAuthorized) {
    return (
      <Link
        className={classnames('nav-link', active && 'active', bootstrapClasses && bootstrapClasses.join(' '))}
        to={to}
        state={state}
        onClick={handler && handler}
      >
        {children}
      </Link>
    )
  }
  return null
}

export const AuthSubmenu = props => {
  const { children, title, id, show, isAuthorized } = props
  if (isAuthorized) {
    return (
      <Fragment>
        <a
          className={classnames('nav-link dropdown-toggle')}
          aria-expanded={show && 'true'}
          data-toggle="collapse"
          id="navbarDropdowns"
          href={`#${id}`}
        >
          {title}
        </a>
        <div className={classnames('nav collapse submenu', show && 'show')} aria-labelledby="navbarDropdowns" id={id}>
          {children}
        </div>
      </Fragment>
    )
  }
  return null
}
