import classnames from 'classnames'
import { Field, useField, useFormikContext } from 'formik'

export const useFormField = allProps => {
  const {
    component,
    formGroupClasses,
    className,
    labelClasses,
    style,
    validate,
    btnText,
    onButtonClick,
    autoFocus,
    ...props
  } = allProps

  const { label, name, type /*, value, */ } = props
  const context = useFormikContext()
  const { values, errors, touched, setFieldValue, setFieldTouched } = context
  const [field] = useField(props)
  const fieldError = touched[name] && errors[name]

  const fromLibraries = {
    classnames,
    Field,
  }
  return {
    component,
    formGroupClasses,
    className,
    labelClasses,
    style,
    validate,
    btnText,
    onButtonClick,
    autoFocus,
    label,
    name,
    type,
    context,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    field,
    props,
    fieldError,
    fromLibraries,
  }
}
