import { API_URL } from './constants'
import { getCompanyId, queryString, responseHandler, errorResponseHandler } from './apiHelpers'

import axios from 'axios'

const instance = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  headers: {
    companyId: getCompanyId(),
  },
})

instance.interceptors.response.use(
  res => responseHandler(res),
  err => errorResponseHandler(err),
)

export const get = (path, params, headers = {}) =>
  instance.get(`${API_URL}${path}${params ? queryString(params) : ''}`, {
    headers: {
      ...headers,
      companyId: getCompanyId(),
    },
  })

export const post = (path, request, params, headers = {}) =>
  instance.post(`${API_URL}${path}/${params ? params : ''}`, request, {
    headers: {
      ...headers,
      companyId: getCompanyId(),
    },
  })

export const apiDelete = (path, data, headers = {}) =>
  instance.delete(`${API_URL}${path}`, {
    headers: {
      ...headers,
      companyId: getCompanyId(),
    },
  })

export const fileDownload = (path, params, headers = {}) =>
  instance.get(`${API_URL}${path}/${params ? queryString(params) : ''}`, {
    responseType: 'blob',
    headers: {
      ...headers,
      companyId: getCompanyId(),
      signal: params && params.signal ? params.signal : null,
    },
  })
