import { Fragment, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { open } from 'services/modal/slice'

import LoadingScript from 'utils/components/LoadingScript'

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

import { isModelLoadingSelector, logout, modelSelector } from 'services/auth/slice'

import {
  changePassword as changePasswordAction,
  clearError,
  errorSelector as userErrorSelector,
} from 'services/user/slice'

import { t, withPrefix } from 'i18n/translation'
import ChangePassword from 'scenes/User/components/ChangePassword'
const pt = withPrefix('components.appHeader')

export const UserInfo = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userModel = useSelector(modelSelector)
  const isModelLoading = useSelector(isModelLoadingSelector)
  const userError = useSelector(userErrorSelector)

  const handleLogout = () => {
    dispatch(logout(navigate))
    navigate('/auth/login')
  }

  const openChangePassword = () => dispatch(open('changePasswordModal'))

  const changePassword = (values, modal) => dispatch(changePasswordAction({ values, modal }))

  const emptyModel = {
    password: '',
    confirmPassword: '',
    oldPassword: '',
  }

  if (!userModel) return null
  const { username, firstname, lastname } = userModel

  return (
    <Fragment>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret className={firstname || lastname ? ' text-capitalize' : ''}>
          <i className="fas fa-solid fa-user pr-2"></i>
          {firstname && lastname ? `${firstname} ${lastname}` : firstname ? firstname : lastname ? lastname : username}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={openChangePassword}>{pt('changePassword.title')}</DropdownItem>
          <hr />
          <DropdownItem onClick={handleLogout}>{pt('logOut')}</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <Suspense fallback={<LoadingScript />}>
        <ChangePassword
          title={t('scenes.user.changePassword.title')}
          onSave={values => changePassword(values, 'changePasswordModal')}
          model={userModel}
          isLoading={isModelLoading}
          emptyModel={emptyModel}
          error={userError}
          onClose={clearError}
          name={'changePasswordModal'}
        />
      </Suspense>
    </Fragment>
  )
}
