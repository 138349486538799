import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'

export const addToast = createAsyncThunk('toast/ADD_TOAST', async ({ type, message }, { getState, dispatch }) => {
  const state = getState()
  const toastId = nanoid()
  const toast = { id: toastId, type, message }
  let timeoutId = null
  if (state.toast.configs.autoClose) {
    timeoutId = setTimeout(() => {
      dispatch(slice.actions.removeToast(toastId))
    }, state.toast.configs.autoCloseDuration * 1000)
  }

  dispatch(slice.actions.addToastWithAutoClose({ timeoutId, ...toast }))

  return { data: state.toast.toasts }
})

export const refreshToastsTimeout = createAsyncThunk(
  'toast/REFRESH_TOASTS_TIMEOUT',
  async (_, { getState, dispatch }) => {
    const toastState = getState().toast
    if (toastState.configs.autoClose) {
      toastState.toasts.map(t =>
        setTimeout(() => dispatch(slice.actions.removeToast(t.id)), (toastState.configs.autoCloseDuration * 1000) / 2),
      )
    }
  },
)

export const slice = createSlice({
  name: 'toast',
  initialState: {
    toasts: [],
    configs: {
      position: 'top-right',
      autoClose: true,
      autoCloseDuration: 10,
    },
  },
  reducers: {
    removeToast: (state, action) => {
      state.toasts = state.toasts.filter(t => t.id !== action.payload)
    },
    setConfigs: (state, action) => {
      state.toasts = action.payload
    },
    addToastWithAutoClose: (state, action) => {
      state.toasts.push(action.payload)
    },
    keepToastOpen: state => {
      state.toasts.map(t => clearTimeout(t.timeoutId))
    },
    refreshToastTimeout: (state, action) => {
      state.toasts = state.toasts.filter(t => t.id !== action.payload)
    },
  },
})

export const { removeToast, setConfigs, keepToastOpen, refreshToastTimeout } = slice.actions

export const toastsSelector = state => state.toast.toasts
export const toastConfigSelector = state => state.toast.configs

export const reducer = slice.reducer
