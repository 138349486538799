import { get as apiGet, post } from 'api'

export const getAll = async request => apiGet('/customer/getlist', request)

export const get = async id => apiGet(`/customer/${id}`)

export const update = async values => post('/customer/edit', values)

export const saveDefaultContact = async (customerId, email) =>
  post('/customerContact/saveDefaultContact', { customerId, email })
