import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { routes } from 'routes'
import * as api from './api'

import { router } from '../../core/Root'
import { addToast } from 'services/toast/slice'

export const get = createAsyncThunk('settings/GET', async (checkingCookie, thunkAPI) => {
  const { data, error } = await api.get(checkingCookie)
  if (checkingCookie && !error) {
    router.navigate(routes.root.path)
  }
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    thunkAPI.fulfillWithValue(data)
    data.logoFileName && thunkAPI.dispatch(getCompanyLogo(data?.logoFileName))
    return data
  }
})

export const update = createAsyncThunk('settings/UPDATE', async (values, thunkAPI) => {
  const { data, error } = await api.update(values)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    await thunkAPI.dispatch(get())
    thunkAPI.dispatch(addToast({ type: 'success', message: 'settingAreSaved' }))
    return data
  }
})

export const getCompanyLogo = createAsyncThunk('settings/GET_COMPANY_LOGO', async (fileName, thunkAPI) => {
  const res = await api.getCompanyLogo(fileName)
  if (res.error) return thunkAPI.rejectWithValue(res.error)
  else {
    return res
  }
})

export const slice = createSlice({
  name: 'settings',
  initialState: {
    model: null,
    isModelLoading: false,
    error: null,
    logo: null,
    isLogoLoading: false,
    isUpdated: false,
  },
  reducers: {
    clearLogo: state => {
      state.isLogoLoading = false
      state.logo = null
    },
    clearError: state => {
      state.error = null
    },
    clearMessages: state => {
      state.isUpdated = false
    },
  },
  extraReducers: builder => [
    builder
      .addCase(get.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.isModelLoading = false
        state.model = payload
      })
      .addCase(get.rejected, (state, { error }) => {
        state.isModelLoading = false
        state.error = error
      })

      .addCase(update.pending, state => {
        state.isUpdated = false
        state.error = null
      })
      .addCase(update.fulfilled, state => {
        state.isUpdated = true
      })
      .addCase(update.rejected, (state, { error }) => {
        state.isUpdated = false
        state.error = error
      })

      .addCase(getCompanyLogo.pending, state => {
        state.isLogoLoading = true
        state.error = null
      })
      .addCase(getCompanyLogo.fulfilled, (state, action) => {
        state.isLogoLoading = false
        state.logo = action.payload
      })
      .addCase(getCompanyLogo.rejected, (state, { error }) => {
        state.isLogoLoading = false
        state.error = error
      }),
  ],
})

export const { clearError, clearLogo, clearMessages } = slice.actions

export const modelSelector = state => state.settings.model
export const isModelLoadingSelector = state => state.settings.isModelLoading
export const isUpdatedSelector = state => state.settings.isUpdated
export const companyLogoSelector = state => state.settings.logo
export const errorSelector = state => state.settings.error
export const isLogoLoadingSelector = state => state.settings.isLogoLoading
export const reducer = slice.reducer
