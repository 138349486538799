import { get, post } from 'api'

export const getAll = async id => get('/document/getlist', { folderId: id })
export const getSefAttachments = async id => get('/document/getSefAttachments', { folderId: id })

export const getMetadata = async id => get('/document/metadata', { documentId: id })

export const deleteItem = async requestBody => post(`/document/delete`, requestBody)

export const download = async id => get('/document/download', { folderId: id })

export const upload = async (id, file, actionToken) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('actionToken', actionToken)
  formData.append('folderId', id)
  return post(`/document`, formData)
}

export const getComment = async id => get(`/document/getComment`, { documentId: id })

export const saveComment = async values => post('/document/saveComment', values)
