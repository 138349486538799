import React from 'react'
import { useFormField } from '../useFormField'

export const RadioButtonField = allProps => {
  const {
    name,
    props,
    label,
    fromLibraries: { Field },
  } = useFormField(allProps)
  return (
    <div className="form-check p-0" role="group">
      <label className="d-flex">
        <Field type="radio" name={name} value={props.value} checked={props.checked} disabled={props.disabled} />
        <span className="pl-1">{label}</span>
      </label>
    </div>
  )
}
