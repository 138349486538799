import React, { useEffect, useState } from 'react'
import { LoadingView } from '../LoadingView'
const DelayedLoadingView = ({ timeout }) => {
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), timeout || 300)

    return () => clearTimeout(timer)
  })

  return showSpinner && <LoadingView />
}

export default DelayedLoadingView
