import { matchRoutes, useLocation } from 'react-router-dom'
import { routes } from 'routes'

const useCurrentPath = () => {
  const location = useLocation()

  const match = matchRoutes(
    Object.keys(routes).map(key => routes[key]),
    location,
  )
  const route = match && match[0].route
  return route?.path
}

export default useCurrentPath
