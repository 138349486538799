import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { hide } from 'services/modal/slice'
import { addToast } from '../toast/slice'
import { get } from '../folder/slice'
import * as api from './api'

export const getAll = createAsyncThunk('document/GET_ALL', async ({ id, requestMetadata = false }, thunkAPI) => {
  const { data, error } = await api.getAll(id)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    if (requestMetadata && data?.length > 0) {
      const leadDocumentId = data.find(d => d.isLeadFile).id
      thunkAPI.dispatch(getMetadata(leadDocumentId))
    }
    return data
  }
})

export const getSefAttachments = createAsyncThunk('document/GET_SEF_ATTACHMENTS', async (id, thunkAPI) => {
  const { data, error } = await api.getSefAttachments(id)

  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const getMetadata = createAsyncThunk('document/GET_METADATA', async (id, thunkAPI) => {
  const { data, error } = await api.getMetadata(id)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const deleteItem = createAsyncThunk('document/DELETE_ITEM', async (body, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.deleteItem({
    ...body,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    const {
      document: { masterId },
    } = thunkAPI.getState()
    thunkAPI.dispatch(getAll({ id: masterId, shouldLoad: true }))
    return data
  }
})

export const download = createAsyncThunk('document/DOWNLOAD', async (id, thunkAPI) => {
  const { data, error } = await api.download(id)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    const {
      document: { masterId },
    } = thunkAPI.getState()
    thunkAPI.dispatch(getAll({ id: masterId, shouldLoad: true }))
    return data
  }
})

export const upload = createAsyncThunk('document/UPLOAD', async ({ id, file, setOpen }, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.upload(id, file, state.folder.actionToken)
  setOpen(false)
  if (error) {
    thunkAPI.dispatch(addToast({ type: 'failure', message: error.errorCode }))

    return thunkAPI.rejectWithValue(error)
  } else {
    const {
      document: { masterId },
    } = thunkAPI.getState()

    state?.folder?.model?.id && thunkAPI.dispatch(get(state?.folder?.model?.id))
    //todo, double request when masterId has value
    masterId && thunkAPI.dispatch(getAll({ id: masterId, shouldLoad: true }))
    thunkAPI.dispatch(addToast({ type: 'success', message: 'FileUploaded' }))
    return data
  }
})
export const getComment = createAsyncThunk('document/GET_COMMENY', async (id, thunkAPI) => {
  const { data, error } = await api.getComment(id)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const saveComment = createAsyncThunk('document/SAVE_COMMENT', async ({ values, modal }, thunkAPI) => {
  const { data, error } = await api.saveComment(values)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    const {
      document: { masterId },
    } = thunkAPI.getState()
    thunkAPI.dispatch(hide(modal))
    thunkAPI.dispatch(getAll({ id: masterId, shouldLoad: true }))
    return data
  }
})
const initialState = {
  masterId: null,
  list: [],
  sefAttachments: [],
  totalSize: 0,
  isListLoading: false,
  isSefAttachmentsLoading: false,
  model: null,
  isModelLoading: false,
  isUploading: false,
  isDownloading: false,
  error: null,
  isDeleting: false,
  isUpdating: false,
  documentCommentModel: null,
  isMetaDataLoading: false,
}

export const slice = createSlice({
  name: 'document',
  initialState: initialState,
  reducers: {
    clearError: state => {
      state.error = null
    },
    resetForm: state => {
      // eslint-disable-next-line no-unused-vars
      state = initialState
    },
  },
  extraReducers: builder => [
    builder
      .addCase(getAll.pending, (state, action) => {
        state.isListLoading = action.meta.arg.shouldLoad
        state.masterId = action.meta.arg.id
        state.error = null
      })
      .addCase(getAll.fulfilled, (state, { payload }) => {
        state.isListLoading = false
        state.list = payload
        state.totalSize = payload.totalCount
      })
      .addCase(getAll.rejected, (state, { error }) => {
        state.isListLoading = false
        state.error = error
      })

      .addCase(getSefAttachments.pending, (state, action) => {
        state.isSefAttachmentsLoading = action.meta.arg.shouldLoad
        state.masterId = action.meta.arg.id
        state.error = null
      })
      .addCase(getSefAttachments.fulfilled, (state, action) => {
        //
        state.sefAttachments = action.payload
        state.isSefAttachmentsLoading = false
      })
      .addCase(getSefAttachments.rejected, (state, { error }) => {
        state.isSefAttachmentsLoading = false
        state.error = error
      })

      .addCase(getMetadata.pending, state => {
        state.isModelLoading = true
        state.isMetaDataLoading = true
        state.model = null
        state.error = null
      })
      .addCase(getMetadata.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.isMetaDataLoading = false
        state.model = action.payload
      })
      .addCase(getMetadata.rejected, (state, action) => {
        state.isModelLoading = false
        state.isMetaDataLoading = false
        state.navigation = null
        state.error = action.error
      })

      .addCase(deleteItem.pending, state => {
        state.isDeleting = true
        state.error = null
      })
      .addCase(deleteItem.fulfilled, state => {
        state.isDeleting = false
      })
      .addCase(deleteItem.rejected, (state, action) => {
        state.isDeleting = false
        state.error = action.error
      })

      .addCase(download.pending, state => {
        state.isDownloading = true
        state.error = null
      })
      .addCase(download.fulfilled, state => {
        state.isDownloading = false
      })
      .addCase(download.rejected, (state, action) => {
        state.isDownloading = false
        state.error = action.error
      })

      .addCase(upload.pending, state => {
        state.isUploading = true
        state.error = null
      })
      .addCase(upload.fulfilled, state => {
        state.isUploading = false
      })
      .addCase(upload.rejected, (state, action) => {
        state.isUploading = false
        state.error = action.payload
      })

      .addCase(getComment.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(getComment.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.documentCommentModel = action.payload
      })
      .addCase(getComment.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      })

      .addCase(saveComment.pending, state => {
        state.isUpdating = true
        state.error = null
      })
      .addCase(saveComment.fulfilled, state => {
        state.isUpdating = false
      })
      .addCase(saveComment.rejected, (state, action) => {
        state.isUpdating = false
        state.error = action.error
      }),
  ],
})

export const { clearError, resetForm } = slice.actions

export const modelSelector = state => state.document.model
export const isModelLoadingSelector = state => state.document.isModelLoading
export const listSelector = state => state.document.list
export const isListLoadingSelector = state => state.document.isListLoading
export const errorSelector = state => state.document.error
export const totalSizeSelector = state => state.document.totalSize
export const masterIdSelector = state => state.document.masterId
export const sefAttachmentsSelector = state => state.document.sefAttachments
export const isSefAttachmentsLoadingSelector = state => state.document.isSefAttachmentsLoading
export const navigationSelector = state => state.document.navigation
export const isDeletingSelector = state => state.document.isDeleting
export const isUploadingSelector = state => state.document.isUploading
export const isUpdatingSelector = state => state.document.isUpdating
export const isDownloadingSelector = state => state.document.isDownloading
export const documentCommentModelSelector = state => state.document.documentCommentModel
export const isMetaDataLoadingSelector = state => state.document.isMetaDataLoading

export const reducer = slice.reducer
