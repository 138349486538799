import { get as apiGet } from 'api'

export const getAll = async ({ folderStatus, emailStatus, ...request }) =>
  apiGet('/emailMessage/getlist', {
    folderStatusList: folderStatus,
    emailStatusList: emailStatus,
    ...request,
  })

export const get = async id => apiGet(`/folder/${id}`)
