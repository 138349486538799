import React from 'react'
// import 'react-app-polyfill/ie11'
// import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { Layout } from 'components/Layout'
import { store } from '@/setup'

import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import './styles/styles.css'

import { unregister } from './registerServiceWorker'

const rootElement = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}>
    <Layout />
  </Provider>,
  rootElement,
)

unregister()
