import { filter, forEach, path, pipe, uniq } from 'ramda'

import locales from './translations/locales.json'

export const DEFAULT_LOCALE = 'en-us'

let translations = {}
export let currentLocale = DEFAULT_LOCALE

export const shortLocale = locale => locale.split('-')[0]
const languages = uniq(locales.map(shortLocale))

// async function importLanguage(language) {
//   console.log('importLanguage', language)
//   await import(`moment/locale/${language}`)
// }
const initMoment = () => {
  pipe(
    filter(language => 'en' !== language),
    // forEach(language => importLanguage(language)),
  )(languages)
  // const browserLang = navigator.language
  // moment.locale(browserLang || DEFAULT_LOCALE)
}
async function getTranslations(local) {
  const res = await import(`./translations/locale-${local}.json`)
  return res.default
}
const loadTranslations = availableTranslations => {
  forEach(locale => {
    if (availableTranslations.includes(locale)) {
      getTranslations(locale).then(res => (translations[locale] = res))
    }
  }, locales)
}

const initI18n = availableTranslations => {
  loadTranslations(availableTranslations)
  initMoment()
}
function capitalizeFirstLetter(string) {
  return string.charAt(0)?.toUpperCase() + string?.slice(1)
}

export const setLocale = newLocale => {
  currentLocale = newLocale
}

export { initI18n, locales, translations }

export const t = key => {
  return path([currentLocale, key], translations) || path([DEFAULT_LOCALE, key], translations) || `[${key}]`
}

export const withPrefix = prefix => key => t(`${prefix}.${key}`)

export const tFormat = (key, ...values) => {
  let message = t(key)
  values.forEach((x, index) => {
    message = message.replace(`{${index}}`, x)
  })
  return message
}

export const formatWithPrefix =
  prefix =>
  (key, ...values) =>
    tFormat(`${prefix}.${key}`, ...values)

export const capitalizeWithPrefix = prefix => key => capitalizeFirstLetter(t(`${prefix}.${key}`))
// export const Translated = ({ id, prefix, ...otherProps }) => {
//   const prefixedId = prefix ? `${prefix}.${id}` : id
//   return <FormattedMessage id={prefixedId} defaultMessage={t(prefixedId)} {...otherProps} />
// }

// export const TranslatedHTML = ({ id, prefix, ...otherProps }) => {
//   const prefixedId = prefix ? `${prefix}.${id}` : id
//   return <FormattedHTMLMessage tagName="div" id={prefixedId} defaultMessage={t(prefixedId)} {...otherProps} />
// }

// export const translatedString = (prefix, id, values) => {
//   let message = t(`${prefix}.${id}`)
//   Object.keys(values).forEach(key => (message = message.replace(`{${key}}`, values[key])))
//   return message
// }
