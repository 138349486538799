import React from 'react'
import * as Yup from 'yup'

import { FormField } from 'components/FormField'

import { ModalBody, ModalFooter, ModalWrapper, formSubmitHandler } from 'components/CustomModal'
import { Formik } from 'formik'
import { formatWithPrefix, withPrefix } from 'i18n/translation'
const pt = withPrefix('scenes.user')
const v = formatWithPrefix('field.validation')

const validationSchema = () =>
  Yup.object().shape({
    oldPassword: Yup.string().required(v('required')).min(12, v('min', 12)),
    password: Yup.string()
      .required(v('required'))
      .min(12, v('min', 12))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, pt('passwordDoesntMeetRequirements')),
    confirmPassword: Yup.string()
      .required(v('required'))
      .oneOf([Yup.ref('password'), null], pt('passwordsMustMatch')),
  })

const ChangePassword = props => {
  const { emptyModel } = props
  return (
    <ModalWrapper {...props}>
      <Formik enableReinitialize={true} validationSchema={validationSchema} initialValues={emptyModel}>
        {formikProps => {
          const withFormikPops = { ...formikProps, ...props }
          return (
            <form onSubmit={e => formSubmitHandler(e, withFormikPops)}>
              <ModalBody {...withFormikPops}>
                <FormField name="oldPassword" label={pt('oldPassword')} type="password" />
                <FormField name="password" label={pt('newPassword')} type="password" withTooltip />
                <FormField name="confirmPassword" label={pt('confirmPassword')} type="password" />
              </ModalBody>
              <ModalFooter {...withFormikPops} />
            </form>
          )
        }}
      </Formik>
    </ModalWrapper>
  )
}

export default ChangePassword
