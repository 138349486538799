import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as api from './api'

export const sendToUserManual = createAsyncThunk('userManual/Upload', async (values, thunkAPI) => {
  const { data, error } = await api.sendToUserManual(values)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    thunkAPI.dispatch(getMetadata())
    return data
  }
})

export const getMetadata = createAsyncThunk('userManual/getMetadata', async (values, thunkAPI) => {
  const { data, error } = await api.getMetadata()
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const slice = createSlice({
  name: 'userManual',
  initialState: {
    metadata: [],
    isModelLoading: false,
    error: null,
    isSent: false,
  },
  reducers: {
    clearIsSent: state => {
      state.isSent = false
    },
  },
  extraReducers: builder => [
    builder
      .addCase(sendToUserManual.pending, state => {
        state.isModelLoading = true
        state.isSent = false
        state.error = null
      })
      .addCase(sendToUserManual.fulfilled, state => {
        state.isModelLoading = false
        state.isSent = true
      })
      .addCase(sendToUserManual.rejected, (state, action) => {
        state.isModelLoading = false
        state.isSent = false
        state.error = action.error
      })
      .addCase(getMetadata.pending, () => {})
      .addCase(getMetadata.fulfilled, (state, action) => {
        state.metadata = action.payload
      })
      .addCase(getMetadata.rejected, (state, action) => {
        state.error = action.error
      }),
  ],
})

export const { clearIsSent } = slice.actions

export const isModelLoadingSelector = state => state.userManual.isModelLoading
export const errorSelector = state => state.userManual.error
export const isSentSelector = state => state.userManual.isSent
export const metadataSelector = state => state.userManual.metadata

export const reducer = slice.reducer
