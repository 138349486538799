import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import * as api from './api'

export const getAll = createAsyncThunk('report/GET_ALL', async (request, thunkAPI) => {
  const { data, error } = await api.getAll(request)
  if (error) return thunkAPI.rejectWithValue(error)
  return thunkAPI.fulfillWithValue(data)
})

export const get = createAsyncThunk('report/GET', async (id, thunkAPI) => {
  const { data, error } = await api.get(id)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const slice = createSlice({
  name: 'report',
  initialState: {
    list: [],
    totalSize: 0,
    isListLoading: false,
    model: null,
    isModelLoading: false,
    error: null,
  },
  reducers: {
    clearError: state => {
      state.error = null
    },
  },
  extraReducers: builder => [
    builder
      .addCase(getAll.pending, state => {
        state.isListLoading = true
        state.error = null
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isListLoading = false
        state.list = action.payload.data
        state.totalSize = action.payload.totalCount
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isListLoading = false
        state.error = action.error
      })
      .addCase(get.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      }),
  ],
})

export const { clearError } = slice.actions

export const listSelector = state => state.report.list
export const totalSizeSelector = state => state.report.totalSize
export const isListLoadingSelector = state => state.report.isListLoading
export const modelSelector = state => state.report.model
export const isModelLoadingSelector = state => state.report.isModelLoading
export const errorSelector = state => state.report.error

export const reducer = slice.reducer
