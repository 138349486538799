import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import { useNavigate } from 'react-router'
import { router } from '../../core/Root'
import { routes } from 'routes'

import * as api from './api'

export const getTrigger = createAsyncThunk('trigger/GET', async (id, thunkAPI) => {
  const { data, error } = await api.getTrigger(id)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const getAllTrigger = createAsyncThunk('trigger/GET_ALL', async (payload, thunkAPI) => {
  const { data, error } = await api.getAllTrigger(payload)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const toggleTriggerEnabled = createAsyncThunk('trigger/TOGGLE_TRIGGER_ENABLED', async (payload, thunkAPI) => {
  const { data, error } = await api.toggleTriggerEnabled(payload)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const deleteTriggerItem = createAsyncThunk('trigger/DELETE_TRIGGER_ITEM', async (payload, thunkAPI) => {
  const { id, tableState } = payload
  const { data, error } = await api.deleteTriggerItem(id)
  if (error) return thunkAPI.rejectWithValue(error)
  thunkAPI.dispatch(getAllTrigger(tableState))
  return data
})

export const addTrigger = createAsyncThunk('trigger/ADD_TRIGGER', async (payload, thunkAPI) => {
  const { data, error } = await api.addTrigger(payload)
  if (error) return thunkAPI.rejectWithValue(error)
  // const history = useNavigate()
  router.navigate(routes.triggers.path)
  return data
})

export const getCompanyDocumentTypes = createAsyncThunk(
  'trigger/GET_COMPANY_DOCUMENT_TYPES',
  async (payload, thunkAPI) => {
    const { data, error } = await api.getCompanyDocumentTypes()
    if (error) return thunkAPI.rejectWithValue(error)
    return data
  },
)

export const slice = createSlice({
  name: 'trigger',
  initialState: {
    triggerList: [],
    triggerModel: null,
    isTriggerModelLoading: false,
    triggerError: null,
    isTriggerListLoading: false,
    isTriggerDeleting: false,
    companyDocumentTypeList: [],
    companyDocumentTypeError: null,
    triggerTotalSize: 0,
  },
  reducers: {
    clearModel: state => {
      state.triggerModel = null
    },
  },
  extraReducers: builder => [
    builder
      .addCase(getAllTrigger.pending, state => {
        state.isTriggerListLoading = true
        state.triggerError = null
      })
      .addCase(getAllTrigger.fulfilled, (state, action) => {
        state.isTriggerListLoading = false
        state.triggerList = action.payload.data
        state.triggerTotalSize = action.payload.totalCount
      })
      .addCase(getAllTrigger.rejected, (state, action) => {
        state.isTriggerListLoading = false
        state.triggerError = action.error
      })
      .addCase(getTrigger.pending, state => {
        // state.isModelLoading = true ??
        state.isTriggerModelLoading = true
        state.triggerError = null
      })
      .addCase(getTrigger.fulfilled, (state, action) => {
        state.isTriggerModelLoading = false
        state.triggerModel = action.payload
      })
      .addCase(getTrigger.rejected, (state, action) => {
        // state.isModelLoading = false
        state.isTriggerModelLoading = false
        state.triggerError = action.payload
      })
      .addCase(deleteTriggerItem.pending, state => {
        state.isTriggerDeleting = true
        state.triggerError = null
      })
      .addCase(deleteTriggerItem.fulfilled, state => {
        state.isTriggerDeleting = false
        state.triggerError = null
      })
      .addCase(deleteTriggerItem.rejected, (state, action) => {
        state.isTriggerDeleting = false
        state.triggerError = action.error
      })
      .addCase(addTrigger.pending, state => {
        state.isTriggerModelLoading = true
        state.triggerError = null
      })
      .addCase(addTrigger.fulfilled, state => {
        state.isTriggerModelLoading = false
      })
      .addCase(addTrigger.rejected, (state, action) => {
        state.isTriggerModelLoading = false
        state.triggerError = action.payload
      })
      .addCase(getCompanyDocumentTypes.pending, () => {})
      .addCase(getCompanyDocumentTypes.fulfilled, (state, action) => {
        state.companyDocumentTypeList = action.payload.result
      })
      .addCase(getCompanyDocumentTypes.rejected, (state, action) => {
        state.companyDocumentTypeError = action.error
      }),
  ],
})

export const { clearModel } = slice.actions

export const triggerListSelector = state => state.trigger.triggerList
export const isTriggerListLoadingSelector = state => state.trigger.isTriggerListLoading
export const triggerTotalSizeSelector = state => state.trigger.triggerTotalSize
export const triggerErrorSelector = state => state.trigger.triggerError
export const triggerModelSelector = state => state.trigger.triggerModel
export const isTriggerModelLoadingSelector = state => state.trigger.isTriggerModelLoading

export const companyDocumentTypeListSelector = state => state.trigger.companyDocumentTypeList

export const reducer = slice.reducer
