import { ModalBody } from './ModalBody'
import { ModalFooter } from './ModalFooter'
import { ModalWrapper } from './ModalWrapper'

import { handleEmptyValues } from '../../utils/helperMethods'

export { ModalBody, ModalFooter, ModalWrapper }

export const formSubmitHandler = (e, props) => {
  e.preventDefault()
  const { values, onSave } = props
  const { validateForm, setFieldTouched } = props

  validateForm().then(errors => {
    const hasErrors = Object.keys(errors).length
    if (hasErrors) {
      Object.keys(errors).forEach(x => setFieldTouched(x))
    } else {
      onSave && onSave(handleEmptyValues(values))
    }
  })
}
