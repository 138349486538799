export const roles = {
  SUPERADMIN: 'Superadmin',
  ADMIN: 'Administrator',
  REFERENT: 'Referent',
  CONTROLLER: 'Controller',
  SENDER: 'Sender',
  AUDITOR: 'Auditor',
  AUDITOR_VAT: 'AuditorVat',
  AUDITOR_RECEIVABLES: 'AuditorReceivables',
  COORDINATOR: 'Coordinator',
}
