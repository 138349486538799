import { get as apiGet, post } from 'api'
import { getFormData } from 'utils/helperMethods'

export const getList = async () => apiGet('/tenant')

export const get = async id => apiGet(`/tenant/${id}`)

export const create = values => {
  const formData = getFormData(values)
  return post('/tenant', formData)
}

export const update = async values => {
  const formData = getFormData(values)
  return post('/tenant/update', formData)
}
