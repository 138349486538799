import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_LOCALE } from 'i18n/translation'

export const slice = createSlice({
  name: 'account',
  initialState: {
    language: null,
  },
  reducers: {
    selectLanguage: (state, { payload }) => {
      state.language = payload
      localStorage.setItem('userLanguage', payload)
    },
  },
})

export const { selectLanguage } = slice.actions

export const allLanguagesSelector = state => state.settings && (state.settings.settings ? state.settings.settings : [])

export const languageSelector = (state, allLanguagesSelector) => {
  return (
    state.account.language ||
    localStorage.getItem('userLanguage') ||
    allLanguagesSelector?.languages[0] ||
    DEFAULT_LOCALE
  )
}

export const reducer = slice.reducer
