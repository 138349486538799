import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { hide } from 'services/modal/slice'
import { get as getFolder } from '../folder/slice'
import { addToast } from '../toast/slice'
import * as api from './api'
import { getApproverInfo } from 'services/folderHistory/slice'

export const get = createAsyncThunk('folderSef/GET', async ({ id }, thunkAPI) => {
  const { data, error } = await api.get(id)
  if (error) {
    return thunkAPI.rejectWithValue(error)
  } else {
    return data
  }
})

export const send = createAsyncThunk('folderSef/SEND', async (model, thunkAPI) => {
  const state = thunkAPI.getState()

  const { data, error } = await api.send({
    ...model,
    actionToken: state.folder.actionToken,
  })
  thunkAPI.dispatch(addToast({ type: 'info', message: 'sendingInProgress' }))
  if (error) {
    thunkAPI.dispatch(getFolder(model.folderId))
    return thunkAPI.rejectWithValue(error)
  } else {
    thunkAPI.fulfillWithValue(data)
    thunkAPI.dispatch(getFolder(model.folderId))
    thunkAPI.dispatch(getApproverInfo(model.folderId))
  }
})

export const cancel = createAsyncThunk('folderSef/CANCEL', async ({ values, modalName }, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.cancel({
    ...values,
    actionToken: state.folder.actionToken,
  })
  thunkAPI.dispatch(hide(modalName))
  if (error) {
    thunkAPI.dispatch(addToast({ type: 'failure', message: error.errorCode }))
    return thunkAPI.rejectWithValue(error)
  } else {
    thunkAPI.fulfillWithValue(data)
    thunkAPI.dispatch(getFolder(values.folderId))
    thunkAPI.dispatch(getApproverInfo(values.folderId))
  }
})

export const storno = createAsyncThunk('folderSef/STORNO', async ({ values, modalName }, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.storno({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) {
    thunkAPI.dispatch(addToast({ type: 'failure', message: error.errorCode }))
    return thunkAPI.rejectWithValue(error)
  } else {
    thunkAPI.dispatch(hide(modalName))
    thunkAPI.dispatch(getFolder(values.folderId))
    thunkAPI.dispatch(getApproverInfo(values.folderId))
    return data
  }
})

export const slice = createSlice({
  name: 'folderSef',
  initialState: {
    model: null,
    isModelLoading: false,
    isCanceling: false,
    EFacturaError: null,
    selections: [],
    cancelResult: null,
    isModelRefreshing: false,
    isEmailSending: false,
    isStornoLoading: false,
    stornoError: null,
  },
  reducers: {
    clearError: state => {
      state.error = null
    },
    updateSelections: (state, { payload }) => {
      function updateSelected(rowId, isSelect) {
        return isSelect ? [...state.selections, rowId] : state.selections.filter(item => item !== rowId)
      }
      state.selections = updateSelected(payload.rowId, payload.isSelect)
    },
    cleareErrorsAndSelections: state => {
      state.EFacturaError = null
      state.selections = []
      state.cancelResult = null
    },
    clearStornoError: state => {
      state.stornoError = null
    },
  },
  extraReducers: builder => [
    builder
      .addCase(get.pending, (state, action) => {
        state.isModelLoading = action.meta.arg.shouldLoad
        state.isModelRefreshing = true
        state.EFacturaError = null
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.model = payload
        state.isModelLoading = false
        state.EFacturaError = payload && payload.errorMessage && { message: payload.errorMessage }
      })
      .addCase(get.rejected, state => {
        state.isModelLoading = false
      })

      .addCase(send.pending, state => {
        state.isModelLoading = true
        state.EFacturaError = null
        state.isEmailSending = true
      })
      .addCase(send.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
        state.EFacturaError = null
        state.isEmailSending = false
      })
      .addCase(send.rejected, (state, action) => {
        state.isModelLoading = false
        state.EFacturaError = action.error
        state.isEmailSending = false
      })

      .addCase(cancel.pending, state => {
        state.isCanceling = true
      })
      .addCase(cancel.fulfilled, (state, action) => {
        state.isCanceling = false
        state.cancelResult = action.payload
      })
      .addCase(cancel.rejected, (state, action) => {
        state.isCanceling = false
        state.cancelResult = action.error
      })

      .addCase(storno.pending, state => {
        state.isStornoLoading = true
        state.stornoError = null
      })
      .addCase(storno.fulfilled, state => {
        state.isStornoLoading = false
      })
      .addCase(storno.rejected, (state, action) => {
        state.isStornoLoading = false
        state.stornoError = action.payload
      }),
  ],
})

export const { clearError, updateSelections, cleareErrorsAndSelections, clearStornoError } = slice.actions

export const modelSelector = state => state.folderSef.model
export const isModelLoadingSelector = state => state.folderSef.isModelLoading
export const EFacturaErrorSelector = state => state.folderSef.EFacturaErrorSelector
export const selectionsSelector = state => state.folderSef.selections
export const cancelResultSelector = state => state.folderSef.cancelResult
export const isCancelingSelector = state => state.folderSef.isCanceling
export const isEmailSendingSelector = state => state.folderSef.isEmailSending
export const isStornoLoadingSelector = state => state.folderSef.isStornoLoading
export const stornoErrorSelector = state => state.folderSef.stornoError

export const reducer = slice.reducer
