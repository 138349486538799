import { stringify } from 'qs'
import { router } from 'core/Root'
import { t } from 'i18n/translation'
import { API_LOGIN_ENDPOINTS, API_URL } from './constants'

export const getCompanyId = () => localStorage.getItem('companyId')

export const handleErrorResponse = response => {
  const { status } = response
  const { fieldName, fieldValue, message, description, errorCode } = response?.data || {}
  if (fieldName != null && fieldName !== '') {
    return {
      fields: {
        [fieldName]: {
          errorCode,
          errorMessage: t(`errorCode.${errorCode}`),
          value: fieldValue,
        },
      },
      errorCode,
      message,
      status,
    }
  }
  return {
    message,
    description,
    errorCode,
    status,
  }
}

export const checkSession = (response, url, checkingCookie) => {
  // const navigate = useNavigate()
  if (
    response.status === 401 &&
    !API_LOGIN_ENDPOINTS.some(l => url.replace(API_URL, '').includes(l)) &&
    !checkingCookie
  ) {
    router.navigate('/auth/login')
    return response
  } else if (!response.ok && response.status !== 400 && !checkingCookie) {
    const { status, statusText } = response
    return { errorCode: status, message: statusText }
  } else {
    return response
  }
}

export const queryString = params => (params ? `?${stringify(params)}` : '')

export const responseHandler = res => ({ data: res?.data })

export const errorResponseHandler = ({ response, config }) => {
  checkSession(response, config?.url, config?.checkingCookie)
  return { error: handleErrorResponse(response) }
}
