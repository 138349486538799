import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as api from './api'
import { FeatureFlags } from 'utils/constants'
import { addToast } from '../toast/slice'

const setFeatureFlags = values => {
  const featureFlags = []
  Object.keys(FeatureFlags).forEach(key => {
    if (values[key]) featureFlags.push(FeatureFlags[key])
    values.featureFlags = featureFlags
    const { [key]: removedProperty, ...result } = values
    values = result
  })
  return values
}

export const getList = createAsyncThunk('tenant/GET_LIST', async (payload, thunkAPI) => {
  const { data, error } = await api.getList(payload)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const get = createAsyncThunk('tenant/GET', async (id, thunkAPI) => {
  const { data, error } = await api.get(id)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const create = createAsyncThunk('tenant/CREATE', async (values, thunkAPI) => {
  const { data, error } = await api.create(setFeatureFlags(values))
  if (error) return thunkAPI.rejectWithValue(error)
  else localStorage.setItem('showActionTitle', true)
  return data
})

export const update = createAsyncThunk('tenant/UPDATE', async (values, thunkAPI) => {
  const { data, error } = await api.update(setFeatureFlags(values))
  if (error) {
    thunkAPI.dispatch(addToast({ type: 'failure', message: error.errorCode }))
    return thunkAPI.rejectWithValue(error)
  } else {
    thunkAPI.dispatch(addToast({ type: 'success', message: 'CompanyHasUpdated' }))
  }
  return data
})

export const slice = createSlice({
  name: 'tenant',
  initialState: {
    list: [],
    isListLoading: false,
    model: {
      name: '',
      logo: null,
      apiKey: '',
      archiverApiKey: '',
      sendgridApiKey: '',
      ipPool: '',
      domain: '',
      documentType: null,
      isEfakturaCompany: false,
      eFakturaApiKey: '',
      integratorId: 0,
      sendSefStatusWebhook: false,
      legalId: '',
      pib: '',
      featureFlags: [],
    },
    isModelLoading: false,
    error: null,
    isCreating: false,
  },
  reducers: {
    clearList: state => {
      state.list = []
    },
    clearModel: state => {
      state.model = {
        name: '',
        logo: null,
        apiKey: '',
        archiverApiKey: '',
        sendgridApiKey: '',
        ipPool: '',
        domain: '',
        documentType: [],
        isEfakturaCompany: false,
        eFakturaApiKey: '',
        integratorId: 0,
        sendSefStatusWebhook: false,
        legalId: '',
        pib: '',
        featureFlags: [],
      }
    },
    clearError: state => {
      state.error = null
    },
  },
  extraReducers: builder => [
    builder
      .addCase(getList.pending, state => {
        state.isListLoading = true
        state.error = null
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.isListLoading = false
        state.list = action.payload
      })
      .addCase(getList.rejected, (state, action) => {
        state.isListLoading = false
        state.error = action.payload
      })
      .addCase(get.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.payload
      })
      .addCase(create.pending, state => {
        state.isCreating = true
        state.error = null
      })
      .addCase(create.fulfilled, state => {
        state.isCreating = false
      })
      .addCase(create.rejected, (state, action) => {
        state.isCreating = false
        state.error = action.payload
      })
      .addCase(update.pending, state => {
        state.isSaving = true
        state.error = null
      })
      .addCase(update.fulfilled, state => {
        state.isSaving = false
      })
      .addCase(update.rejected, (state, action) => {
        state.isSaving = false
        state.error = action.payload
      }),
  ],
})

export const { clearError, clearModel, clearList } = slice.actions

export const listSelector = state => state.tenant.list
export const isListLoadingSelector = state => state.tenant.isListLoading
export const modelSelector = state => state.tenant.model
export const isModelLoadingSelector = state => state.tenant.isModelLoading
export const isSavedSelector = state => state.tenant.isSaved
export const errorSelector = state => state.tenant.error
export const isCreatingSelector = state => state.tenant.isCreating

export const reducer = slice.reducer
