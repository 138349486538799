import React from 'react'
import { useFormField } from '../useFormField'

export const RadioButton = allPros => {
  const { setFieldValue, setFieldTouched, name, label, props } = useFormField(allPros)
  const onRadioButtonClick = (e, name) => {
    setFieldValue(name, e.target.value).then(() => setFieldTouched(name))
  }
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        name={name}
        id={label + name}
        defaultChecked={props.checked}
        value={props.value}
        onClick={e => onRadioButtonClick(e, name)}
      />
      <label className="form-check-label" htmlFor={label + name}>
        {label}
      </label>
    </div>
  )
}
