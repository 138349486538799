import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as api from './api'

export const GetMassJobs = createAsyncThunk('activityLog/MassJobs', async (request, thunkAPI) => {
  const { data, error } = await api.GetMassJobs(request)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const GetMassJobTasks = createAsyncThunk('activityLog/MassJobs/Tasks', async (request, thunkAPI) => {
  const { data, error } = await api.GetMassJobTasks(request.id, request.tableState)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

const initialState = {
  list: [],
  totalCount: 0,
  count: 0,
  isListLoading: false,
  error: null,
  tasks: [],
  isTasksLoading: false,
  tasksError: null,
  tasksCount: 0,
}

const activityLogSlice = createSlice({
  name: 'activityLog',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(GetMassJobs.pending, state => {
        state.isListLoading = true
        state.error = null
      })
      .addCase(GetMassJobs.fulfilled, (state, action) => {
        state.isListLoading = false
        state.list = action.payload.data
        state.totalCount = action.payload.totalCount
        state.count = action.payload.totalCount
      })
      .addCase(GetMassJobs.rejected, (state, action) => {
        state.isListLoading = false
        state.error = action.error
      })
      .addCase(GetMassJobTasks.pending, state => {
        state.isTasksLoading = true
        state.tasksError = null
      })
      .addCase(GetMassJobTasks.fulfilled, (state, action) => {
        state.isTasksLoading = false
        state.tasks = action.payload.data
        state.tasksCount = action.payload.totalCount
      })
      .addCase(GetMassJobTasks.rejected, (state, action) => {
        state.isTasksLoading = false
        state.tasksError = action.error
      })
  },
})

export const { reset } = activityLogSlice.actions

export const activityLogsListSelector = state => state.activityLog.list
export const activityLogsCountSelector = state => state.activityLog.count
export const activityLogsLoadingSelector = state => state.activityLog.isListLoading
export const activityLogsErrorSelector = state => state.activityLog.error
export const activityLogsTotalCountSelector = state => state.activityLog.totalCount

export const activityLogTasksSelector = state => state.activityLog.tasks
export const activityLogTasksLoadingSelector = state => state.activityLog.isTasksLoading
export const activityLogTasksErrorSelector = state => state.activityLog.tasksError
export const activityLogTasksCountSelector = state => state.activityLog.tasksCount

export const reducer = activityLogSlice.reducer
