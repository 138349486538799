import React from 'react'

import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import './styles.css'

const ReactSelect = props => {
  const {
    options: propOptions,
    keyProp,
    text,
    emptyText,
    isMulti,
    isClearable,
    placeholder,
    isSearchable,
    isDisabled,
    onChange,
    value,
    fieldError,
    className,
    areSelectionsGrouped,
    ...rest
  } = props

  const { onCreateOption } = props
  const options = propOptions
  if (emptyText) options.unshift({ [keyProp]: null, [text]: emptyText })
  const handleChange = (value, meta) => {
    const mapped = mapWithMultiCheck(value, mapToFieldValue, isMulti)

    onChange(mapped, meta)
  }
  const mapToFieldValue = x => x.value
  const mapToRsModel = x => ({ value: x[keyProp], label: x[text] })
  const mapWithMultiCheck = (s, mapFunc, isMulti) => (isMulti ? (s ? s.map(mapFunc) : []) : s ? mapFunc(s) : '')

  const getelectedElements = () => {
    let selectedElements = []
    if (areSelectionsGrouped) {
      const groupOptions = options.reduce((accumulator, current) => accumulator.concat(current?.options), [])
      selectedElements = groupOptions?.find(o => value && o[keyProp] === value)
    } else {
      selectedElements = isMulti
        ? options?.filter(o => value && value.includes(o[keyProp]))
        : options?.find(o => value && o[keyProp] === value)
    }

    return selectedElements
  }

  return (
    <div className={className}>
      <ReactSelectInternal
        value={mapWithMultiCheck(getelectedElements(), mapToRsModel, isMulti)}
        options={areSelectionsGrouped ? options : options && options.map(mapToRsModel)}
        onChange={onChange && ((value, meta) => handleChange(value, meta))}
        isMulti={isMulti}
        isClearable={isClearable || false}
        placeholder={placeholder || ''}
        onCreateOption={onCreateOption}
        fieldError={fieldError}
        isSearchable={isSearchable === undefined ? true : isSearchable}
        isDisabled={isDisabled}
        {...rest}
      />
    </div>
  )
}

const rsClassName = 'react-select-container'
const rsClassNamePrefix = 'react-select'
const rsComponents = {
  Control: ({ selectProps: { fieldError }, ...rest }) => {
    return <components.Control {...rest} className={fieldError && 'is-invalid'} />
  },
  IndicatorSeparator: () => null,
  DropdownIndicator: () => (
    <div className="react-select__indicator react-select__dropdown-indicator">
      <i className="fas fa-chevron-down fa-sm"></i>
    </div>
  ),
  ClearIndicator: ({ innerRef, innerProps }) => (
    <div ref={innerRef} {...innerProps} className="react-select__indicator react-select__dropdown-indicator">
      <i className="fas fa-times fa-sm"></i>
    </div>
  ),
}

const ReactSelectInternal = props => {
  const {
    name,
    options,
    isMulti,
    isClearable,
    placeholder,
    onChange,
    value,
    fieldError,
    isSearchable,
    isDisabled,
    onCreateOption,
    onFocus,
    isLoading,
    filterOption,
    onInputChange,
    formatOptionLabel,
  } = props
  if (onCreateOption)
    return (
      <CreatableSelect
        value={value}
        onChange={onChange}
        name={name}
        options={options}
        isMulti={isMulti}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        className={rsClassName}
        classNamePrefix={rsClassNamePrefix}
        components={rsComponents}
        fieldError={fieldError}
        isDisabled={isDisabled}
        onCreateOption={onCreateOption}
      />
    )
  return (
    <Select
      // className="is-invalid"
      value={value}
      onChange={onChange}
      name={name}
      options={options}
      isMulti={isMulti}
      placeholder={placeholder}
      isClearable={isClearable}
      isSearchable={isSearchable}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={false}
      className={rsClassName}
      classNamePrefix={rsClassNamePrefix}
      components={rsComponents}
      fieldError={fieldError}
      isDisabled={isDisabled}
      onFocus={onFocus}
      isLoading={isLoading}
      filterOption={filterOption}
      onInputChange={onInputChange}
      formatOptionLabel={formatOptionLabel}
    />
  )
}

export default ReactSelect
