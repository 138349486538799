import { roles } from 'auth/roles'

// prettier-ignore
export const routes = {
  root: { path: '/' },
  login: { path: '/auth/login' },
  reject: { path: '/reject/:guid' },
  activityLogs: { path: '/activity-log', roles: [roles.REFERENT, roles.AUDITOR, roles.SENDER, roles.CONTROLLER, roles.AUDITOR_VAT, roles.AUDITOR_RECEIVABLES, roles.COORDINATOR] },
  activityLog: { path: '/activity-log/:id', roles: [roles.REFERENT, roles.AUDITOR, roles.SENDER, roles.CONTROLLER, roles.AUDITOR_VAT, roles.AUDITOR_RECEIVABLES, roles.COORDINATOR] },
  customer: { path: '/customer', roles: [roles.ADMIN, roles.COORDINATOR, roles.REFERENT] },
  tenant: { path: '/tenant', roles: [roles.SUPERADMIN] },
  tenantCreate: { path: '/tenant/create', roles: [roles.SUPERADMIN] },
  tenantDetails: { path: '/tenant/:id', roles: [roles.SUPERADMIN] },
  folder: { path: '/folder', roles: [roles.REFERENT, roles.AUDITOR, roles.SENDER, roles.CONTROLLER, roles.AUDITOR_VAT, roles.AUDITOR_RECEIVABLES, roles.COORDINATOR] },
  folderDetails: { path: '/folder/:id', roles: [roles.REFERENT, roles.AUDITOR, roles.SENDER, roles.CONTROLLER, roles.AUDITOR_VAT, roles.AUDITOR_RECEIVABLES, roles.COORDINATOR] },
  folderDocument: { path: '/folder/:id/document', roles: [roles.REFERENT, roles.AUDITOR, roles.SENDER, roles.CONTROLLER, roles.AUDITOR_VAT, roles.AUDITOR_RECEIVABLES, roles.COORDINATOR] },
  folderDocumentWithPdf: { path: '/folder/:id/document/:pdf' },
  folderDocumentWithPdfAndId: { path: '/folder/:id/document/:pdf/:pdfId' },
  // folderHistory: { path: '/folder/:id/history', roles: [roles.REFERENT, roles.AUDITOR, roles.SENDER, roles.CONTROLLER, roles.AUDITOR_VAT, roles.AUDITOR_RECEIVABLES, roles.COORDINATOR] },
  folderSef: { path: '/folder/:id/sef', roles: [roles.AUDITOR, roles.SENDER, roles.REFERENT] },
  folderEmail: { path: '/folder/:id/email', roles: [roles.AUDITOR, roles.SENDER] },
  // undispatched: { path: '/undispatched', roles: [roles.COORDINATOR] },
  // undispatchedDetails: { path: '/undispatched/:id', roles: [roles.COORDINATOR] },
  // undispatchedDocument: { path: '/undispatched/:id/document', roles: [roles.COORDINATOR] },
  // undispatchedDocumentWithPdf: { path: '/undispatched/:id/document/:pdf' },
  // undispatchedDocumentWithPdfAndId: { path: '/undispatched/:id/document/:pdf/:pdfId' },
  // undispatchedHistory: { path: '/undispatched/:id/history', roles: [roles.COORDINATOR] },
  // undispatchedInvoice: { path: '/undispatched/:id/sef', roles: [roles.COORDINATOR] },
  // undispatchedEmail: { path: '/undispatched/:id/email', roles: [roles.COORDINATOR] },
  // reports: { roles: [roles.AUDITOR, roles.COORDINATOR,roles.AUDITOR_VAT, roles.AUDITOR_RECEIVABLES, roles.SENDER] },
  reportEmail: { path: '/reports/email', roles: [roles.AUDITOR, roles.SENDER, roles.AUDITOR_VAT, roles.AUDITOR_RECEIVABLES] },
  settings: { roles: [roles.ADMIN] },
  user: { path: '/settings/user', roles: [roles.ADMIN] },
  settingsCompanyInformation: { path: '/settings/company', roles: [roles.ADMIN] },
  approvals: { path: '/settings/approvalFlows', roles: [roles.ADMIN] },
  approvalFlows: { path: '/settings/approvalFlows/:id/:isClone?', roles: [roles.ADMIN] },
  triggers: { path: '/settings/triggers', roles: [roles.ADMIN] },
  // triggerFlows: { path: '/settings/triggers/:id', roles: [roles.ADMIN] },
  triggerFlows: { path: '/settings/triggers/:id/:isClone?', roles: [roles.ADMIN] },
  settingsEmailTemplate: { path: '/settings/template', roles: [roles.ADMIN] },
  settingsGeneral: { path: '/settings/general', roles: [roles.ADMIN] },
  help: { roles: [roles.ADMIN, roles.SUPER_ADMIN, roles.REFERENT, roles.AUDITOR, roles.SENDER, roles.CONTROLLER, roles.AUDITOR_VAT, roles.AUDITOR_RECEIVABLES, roles.COORDINATOR] },
  helpManual: { path: '/help/manual', roles: [roles.ADMIN, roles.SUPER_ADMIN, roles.REFERENT, roles.AUDITOR, roles.SENDER, roles.CONTROLLER, roles.AUDITOR_VAT, roles.AUDITOR_RECEIVABLES, roles.COORDINATOR] },
  helpContact: { path: '/help/contact', roles: [roles.ADMIN] },
  myActivities: { path: '/myActivities', roles:  [roles.REFERENT, roles.CONTROLLER, roles.SENDER, roles.COORDINATOR] },
  newDocuments : {path: '/myActivities/new', roles: [roles.REFERENT]},
  readyForControlDocuments : {path: '/myActivities/readyForControl', roles: [roles.CONTROLLER]},
  readyForSendingDocuments : {path: '/myActivities/readyForSending', roles: [roles.SENDER]},
  sentDocuments : {path: '/myActivities/sent', roles: [roles.SENDER]},
  undispatched: { path: '/myActivities/undispatched', roles: [roles.COORDINATOR] },
  undispatchedDetails: { path: 'myActivities/undispatched/:id', roles: [roles.COORDINATOR] },
  undispatchedDocument: { path: 'myActivities/undispatched/:id/document', roles: [roles.COORDINATOR] },
  undispatchedDocumentWithPdf: { path: 'myActivities/undispatched/:id/document/:pdf' },
  undispatchedDocumentWithPdfAndId: { path: 'myActivities/undispatched/:id/document/:pdf/:pdfId' },
  undispatchedHistory: { path: 'myActivities/undispatched/:id/history', roles: [roles.COORDINATOR] },
  undispatchedInvoice: { path: 'myActivities/undispatched/:id/sef', roles: [roles.COORDINATOR] },
  undispatchedEmail: { path: 'myActivities/undispatched/:id/email', roles: [roles.COORDINATOR] },
  rejectedDocuments : {path: '/myActivities/rejected', roles: [roles.REFERENT]},
  audit : {path: '/audit', roles: [roles.AUDITOR_VAT, roles.AUDITOR_RECEIVABLES]},
  userManual: { path: '/userManual', roles: [roles.SUPERADMIN] },
  dashboard: {  path: '/dashboard', roles: [roles.REFERENT, roles.SENDER, roles.CONTROLLER, roles.COORDINATOR] },
}
