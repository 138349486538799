import { get, post } from 'api'

export const getAll = async id => get(`/history/${id}`)

//-------------------Folder Actions---------------------
export const approveForControl = async ({ comment, folderId, actionToken }) =>
  post('/folderApproval/approveForControl', { comment, folderId, actionToken })

export const approveForControlAndAssignTo = async ({ comment, folderId, assignToUserId, actionToken }) =>
  post('/folderApproval/approveForControlAndAssignTo', {
    comment,
    folderId,
    assignToUserId,
    actionToken,
  })

export const approveForSending = async ({ comment, folderId, actionToken }) =>
  post('/folderApproval/approveForSending', { comment, folderId, actionToken })

export const approveForSendingAndAssignTo = async ({ comment, folderId, assignToUserId, actionToken }) =>
  post('/folderApproval/approveForSendingAndAssignTo', {
    comment,
    folderId,
    assignToUserId,
    actionToken,
  })

export const approveAndRequestAdditionalApproval = async ({ comment, folderId, assignToUserId, actionToken }) =>
  post('/folderApproval/approveAndRequestAdditionalControl', {
    comment,
    folderId,
    assignToUserId,
    actionToken,
  })

export const rejectControl = async ({ comment, folderId, actionToken }) =>
  post('/folderApproval/rejectControl', { comment, folderId, actionToken })

export const rejectControlAndAssignTo = async ({ comment, folderId, assignToUserId, actionToken }) =>
  post('/folderApproval/rejectControlAndAssignTo', {
    comment,
    folderId,
    assignToUserId,
    actionToken,
  })

export const requestAudit = async ({ comment, folderId, actionToken }) =>
  post('/folderApproval/requestAudit', { comment, folderId, actionToken })

export const requestAuditAndAssignTo = async ({ comment, folderId, assignToUserId, actionToken }) =>
  post('/folderApproval/requestAuditAndAssignTo', {
    comment,
    folderId,
    assignToUserId,
    actionToken,
  })

export const rejectSend = async ({ comment, folderId, actionToken }) =>
  post('/folderApproval/rejectSending', { comment, folderId, actionToken })

export const rejectSendAndAssignTo = async ({ comment, folderId, assignToUserId, actionToken }) =>
  post('/folderApproval/rejectSendingAndAssignTo', {
    comment,
    folderId,
    assignToUserId,
    actionToken,
  })

export const completeVatAudit = async ({ comment, folderId, actionToken }) =>
  post('/folderApproval/completeVatAudit', { comment, folderId, actionToken })

export const completeVatAuditAndAssignTo = async ({ comment, folderId, assignToUserId, actionToken }) =>
  post('/folderApproval/completeVatAuditAndAssignTo', {
    comment,
    folderId,
    assignToUserId,
    actionToken,
  })

export const rejectVatAudit = async ({ comment, folderId, actionToken }) =>
  post('/folderApproval/rejectVatAudit', { comment, folderId, actionToken })

export const rejectVatAuditAndAssignTo = async ({ comment, folderId, assignToUserId, actionToken }) =>
  post('/folderApproval/rejectVatAuditAndAssignTo', {
    comment,
    folderId,
    assignToUserId,
    actionToken,
  })

export const completeAudit = async ({ postingOrder, comment, folderId, actionToken }) =>
  post('/folderApproval/completeAudit', { postingOrder, comment, folderId, actionToken })

export const rejectAudit = async ({ comment, folderId, actionToken }) =>
  post('/folderApproval/rejectAudit', { comment, folderId, actionToken })

export const rejectAuditAndAssignTo = async ({ comment, folderId, assignToUserId, actionToken }) =>
  post('/folderApproval/rejectAuditAndAssignTo', {
    comment,
    folderId,
    assignToUserId,
    actionToken,
  })

export const rejectAudited = async ({ comment, folderId, actionToken }) =>
  post('/folderApproval/rejectAudited', { comment, folderId, actionToken })

export const rejectAuditedAndAssignTo = async ({ comment, folderId, assignToUserId, actionToken }) =>
  post('/folderApproval/rejectAuditedAndAssignTo', {
    comment,
    folderId,
    assignToUserId,
    actionToken,
  })

export const rejectSent = async ({ comment, folderId, actionToken }) =>
  post('/folderApproval/rejectSent', { comment, folderId, actionToken })

export const rejectSentAndAssignTo = async ({ comment, folderId, assignToUserId, actionToken }) =>
  post('/folderApproval/rejectSentAndAssignTo', {
    comment,
    folderId,
    assignToUserId,
    actionToken,
  })

export const dispatchToFlow = async ({ flowDefinitionId, folderId, actionToken, comment }) =>
  post('/folderApproval/dispatchToFlow', {
    flowDefinitionId,
    folderId,
    actionToken,
    comment,
  })

export const assignForApproval = async ({ comment, folderId, assignToUserId, actionToken }) => {
  return post('/folderApproval/assignForApproval', {
    comment,
    folderId,
    assignToUserId,
    actionToken,
  })
}

export const getApproverInfo = async id => get(`/folderApproval/getApproverInfo`, { folderId: id })

export const clientReject = async ({ guid, ...values }) =>
  post('/folderStatus/clientRejectFolder', {
    emailGuid: guid,
    ...values,
  })
