import React from 'react'
import { useFormField } from '../useFormField'

export const CheckBox = allProps => {
  const {
    formGroupClasses,
    className,
    style,
    field,
    labelClasses,
    label,
    props,
    name,
    fromLibraries: { classnames, Field },
  } = useFormField(allProps)
  return (
    <div className={classnames('form-group', formGroupClasses, className)} style={style}>
      <div className="form-check">
        <label>
          <Field {...props} id={name} value={field.value} checked={field.value} className="form-check-input" />
          <span className={classnames(labelClasses, 'form-check-label')}>{label}</span>
        </label>
      </div>
    </div>
  )
}
