import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { hide } from '../modal/slice'

import * as api from './api'

export const get = createAsyncThunk('customer/GET', async (id, thunkAPI) => {
  const { data, error } = await api.get(id)
  if (error) thunkAPI.rejectWithValue(error)
  return data
})

export const getAll = createAsyncThunk('customer/GET_ALL', async (payload, thunkAPI) => {
  const { data, error } = await api.getAll(payload)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const saveDefaultContact = createAsyncThunk('customer/SAVE_DEFAULT_CONTACT', async (payload, thunkAPI) => {
  const { id, defaultContact, modal, tableState } = payload
  const { data, error } = await api.saveDefaultContact(id, defaultContact)
  if (error) return thunkAPI.rejectWithValue(error)

  thunkAPI.dispatch(hide(modal))
  thunkAPI.dispatch(getAll(tableState))
  return data
})

export const update = createAsyncThunk('customer/UPDATE', async (payload, thunkAPI) => {
  const { values, modal, tableState } = payload
  const { data, error } = await api.update(values)
  if (error) return thunkAPI.rejectWithValue(error)

  const { id, defaultContact } = values
  thunkAPI.dispatch(saveDefaultContact({ id, defaultContact, modal, tableState }))
  return data
})

export const slice = createSlice({
  name: 'customer',
  initialState: {
    list: [],
    totalSize: 0,
    isListLoading: false,
    model: null,
    isModelLoading: false,
    isUpdating: false,
    error: null,
  },
  reducers: {
    clearError: state => {
      state.error = null
    },
    clearList: state => {
      state.list = []
    },
  },
  extraReducers: builder => [
    builder
      .addCase(getAll.pending, state => {
        state.isListLoading = true
        state.error = null
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isListLoading = false
        state.list = action.payload.data
        state.totalSize = action.payload.totalCount
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isListLoading = false
        state.error = action.error
      })
      .addCase(get.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      })
      .addCase(update.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(update.fulfilled, state => {
        state.isModelLoading = false
      })
      .addCase(update.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      })
      .addCase(saveDefaultContact.pending, state => {
        state.isUpdating = true
      })
      .addCase(saveDefaultContact.fulfilled, state => {
        state.isUpdating = false
      })
      .addCase(saveDefaultContact.rejected, (state, action) => {
        state.isUpdating = false
        state.error = action.error
      }),
  ],
})

export const { clearError, clearList } = slice.actions

export const listSelector = state => state.customer.list
export const isListLoadingSelector = state => state.customer.isListLoading
export const totalSizeSelector = state => state.customer.totalSize
export const errorSelector = state => state.customer.error
export const modelSelector = state => state.customer.model
export const isModelLoadingSelector = state => state.customer.isModelLoading

export const reducer = slice.reducer
