import { post, get } from 'api'

export const sendToUserManual = async values => {
  const formData = new FormData()
  for (const property in values) {
    formData.append(property, values[property])
  }
  return post('/userManual/upload', formData)
}

export const getMetadata = async () => get('/UserManual/GetMetadata')
