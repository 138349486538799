import React, { Suspense } from 'react'

import { AppHeader } from 'components/AppHeader'
import { languageSelector } from '../../services/account/slice'

import { useSelector } from 'react-redux'
import { Outlet } from 'react-router'
import styles from './styles.module.css'
import DelayedLoadingView from 'components/DelayedLoadingView'

export const HomepageLayout = () => {
  const language = useSelector(languageSelector)
  return (
    <Suspense fallback={<DelayedLoadingView />}>
      <AppHeader publicMode />
      <div className="container d-flex justify-content-center align-items-center min-h-100">
        <div className={styles['component-holder']}>
          <Outlet context={language} />
        </div>
      </div>
    </Suspense>
  )
}
