import React from 'react'
import { useFormField } from '../useFormField'

export const File = allProps => {
  const {
    props: { accept, onChange },
    setFieldTouched,
    setFieldValue,
    name,
    labelClasses,
    field,
    label,
    fieldError,
    formGroupClasses,
    fromLibraries: { classnames },
  } = useFormField(allProps)
  const uploadHandler = e => {
    setFieldValue(name, e.target.files[0])
      .then(() => setFieldTouched(name))
      .then(onChange && onChange(e.target.files[0]))
  }
  return (
    <div className={classnames('form-group', formGroupClasses)}>
      {label && (
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
      )}
      <div className="custom-file">
        <input
          className={classnames('custom-file-input', fieldError && 'is-invalid')}
          accept={accept}
          type="file"
          onChange={uploadHandler}
        />
        <label className="custom-file-label">{field.value && field.value.name}</label>
        {fieldError && <small className="form-text text-danger">{fieldError}</small>}
      </div>
    </div>
  )
}
