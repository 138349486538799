import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { toastConfigSelector, toastsSelector } from 'services/toast/slice'
import ToastList from './components/ToastList'

const ToastWrapper = () => {
  const toasts = useSelector(toastsSelector)
  const config = useSelector(toastConfigSelector)

  return <ToastList data={toasts} position={config.position} />
}

ToastWrapper.defaultProps = {
  position: 'bottom-right',
  autoClose: true,
  autoCloseDuration: 4,
}

ToastWrapper.propTypes = {
  data: PropTypes.string,
  autoClose: PropTypes.bool,
  autoCloseDuration: PropTypes.number,
}
export default ToastWrapper
