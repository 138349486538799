import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { routes } from 'routes'
import { router } from '../../core/Root'

import * as api from './api'

export const add = createAsyncThunk('approvals/ADD', async (values, thunkAPI) => {
  const { data, error } = await api.add(values)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    router.navigate(routes.approvals.path)
    return data
  }
})

export const get = createAsyncThunk('approvals/GET', async (id, thunkAPI) => {
  const { data, error } = await api.get(id)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const getAll = createAsyncThunk(
  'approvals/GET_ALL',
  async (
    tableState = {
      page: 1,
      sizePerPage: 10,
      sortField: 'name',
      sortOrder: 'desc',
    },
    thunkAPI,
  ) => {
    const { data, error } = await api.getAll(tableState)
    if (error) thunkAPI.rejectWithValue(error)
    return data
  },
)

export const deleteItem = createAsyncThunk('approvals/DELETE_ITEM', async ({ id, tableState }, thunkAPI) => {
  const { data, error } = await api.deleteItem(id)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    thunkAPI.dispatch(getAll(tableState))
    return data
  }
})

export const toggleFlowEnabled = createAsyncThunk('approvals/TOGGLE_FLOW_ENABLED', async (id, thunkAPI) => {
  const { data, error } = await api.toggleFlowEnabled(id)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const getCompanyDocumentTypes = createAsyncThunk(
  'approvals/GET_COMPANY_DOCUMENT_TYPES',
  async (payload, thunkAPI) => {
    const { data, error } = await api.getCompanyDocumentTypes()
    if (error) return thunkAPI.rejectWithValue(error)
    return data
  },
)

export const slice = createSlice({
  name: 'approvals',
  initialState: {
    list: [],
    totalSize: 0,
    model: null,
    isListLoading: false,
    isModelLoading: false,
    error: null,
    isDeleting: false,
    companyDocumentTypeList: [],
    companyDocumentTypeError: null,
  },
  reducers: {
    clearModel: state => {
      state.model = null
      state.list = []
    },
    clearList: state => {
      state.list = []
    },
  },
  extraReducers: builder => [
    builder
      .addCase(get.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.payload
      })

      .addCase(getAll.pending, state => {
        state.isListLoading = true
        state.error = null
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isListLoading = false
        state.list = action.payload?.data
        state.totalSize = action.payload?.totalCount
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isListLoading = false
        state.error = action.error
      })

      .addCase(add.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(add.fulfilled, state => {
        state.isModelLoading = false
      })
      .addCase(add.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.payload
      })

      .addCase(deleteItem.pending, state => {
        state.isDeleting = true
        state.error = null
      })
      .addCase(deleteItem.fulfilled, state => {
        state.isDeleting = false
        state.error = null
      })
      .addCase(deleteItem.rejected, (state, action) => {
        state.isDeleting = false
        state.error = action.error
      })

      .addCase(toggleFlowEnabled.pending, () => {})
      .addCase(toggleFlowEnabled.fulfilled, () => {})
      .addCase(toggleFlowEnabled.rejected, () => {})
      .addCase(getCompanyDocumentTypes.pending, () => {})
      .addCase(getCompanyDocumentTypes.fulfilled, (state, action) => {
        //
        state.companyDocumentTypeList = action.payload
      })
      .addCase(getCompanyDocumentTypes.rejected, (state, action) => {
        state.companyDocumentTypeError = action.error
      }),
  ],
})

export const { clearModel, clearList } = slice.actions

export const listSelector = state => state.approvals.list
export const isListLoadingSelector = state => state.approvals.isListLoading
export const totalSizeSelector = state => state.approvals.totalSize
export const errorSelector = state => state.approvals.error
export const modelSelector = state => state.approvals.model
export const isModelLoadingSelector = state => state.approvals.isModelLoading
export const companyDocumentTypeListSelector = state => state.approvals.companyDocumentTypeList

export const reducer = slice.reducer
