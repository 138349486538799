import { t } from 'i18n/translation'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { hide } from 'services/modal/slice'

export const ModalFooter = props => {
  const {
    cancelTitle,
    onClose,
    saveTitle,
    onSave,
    confirm,
    confirmButtonTitle,
    onConfirm,
    name,
    children,
    setModalRequestSucceed,
  } = props
  const dispatch = useDispatch()
  const closeHandler = () => {
    onClose && onClose()
    dispatch(hide(name))
    setModalRequestSucceed && setModalRequestSucceed(false)
  }

  const confirmHandler = () => {
    onConfirm && onConfirm()
    dispatch(hide(name))
  }

  return (
    <Modal.Footer>
      <button type="button" className="btn btn-gray" onClick={closeHandler}>
        {cancelTitle ? cancelTitle : t('common.cancel')}
      </button>
      {onSave && (
        <button type={'submit'} className="btn btn-primary">
          {saveTitle ? saveTitle : t('common.save')}
        </button>
      )}
      {(confirm || confirmButtonTitle) && (
        <button type="button" className="btn btn-primary" onClick={confirmHandler}>
          {confirmButtonTitle ? confirmButtonTitle : t('common.ok')}
        </button>
      )}
      {children}
    </Modal.Footer>
  )
}
