import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { folderStatus } from 'utils/constants'
import * as api from './api'

const initialState = {
  isLoading: false,
  newList: [],
  totalSizeNew: [],
  readyForControlList: [],
  totalSizeReadyForControl: [],
  readyForSendingList: [],
  totalSizeReadyForSending: [],
  rejectedList: [],
  totalSizeRejected: [],
  undispatchedList: [],
  totalSizeUndispatched: [],
  error: null,
}

export const getAllFolderGroups = createAsyncThunk('dashboard/GET_FOLDER_GROUPS', async thunkAPI => {
  const { data, error } = await api.getFolderGroups()
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    // Define your reducers here
  },
  extraReducers: builder => [
    builder.addCase(getAllFolderGroups.pending, state => {
      state.isLoading = true
    }),
    builder.addCase(getAllFolderGroups.fulfilled, (state, action) => {
      state.isLoading = false

      if (action.payload.undispatched) {
        state.undispatchedList = action.payload.undispatched.data
        state.totalSizeUndispatched = action.payload.undispatched.totalCount
      }

      action.payload.groupedByStatuses.forEach(folders => {
        switch (folders.statusId) {
          case folderStatus.New:
            state.newList = folders.data
            state.totalSizeNew = folders.totalCount
            break
          case folderStatus.ReadyForControl:
            state.readyForControlList = folders.data
            state.totalSizeReadyForControl = folders.totalCount
            break
          case folderStatus.ReadyForSending:
            state.readyForSendingList = folders.data
            state.totalSizeReadyForSending = folders.totalCount
            break
          case folderStatus.Rejected:
            state.rejectedList = folders.data
            state.totalSizeRejected = folders.totalCount
            break
          default:
            break
        }
      })
    }),
    builder.addCase(getAllFolderGroups.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error
    }),
  ],
})

export const newListSelector = state => state.dashboard.newList
export const totalSizeNewSelector = state => state.dashboard.totalSizeNew
export const readyForControlListSelector = state => state.dashboard.readyForControlList
export const totalSizeReadyForControlSelector = state => state.dashboard.totalSizeReadyForControl
export const readyForSendingListSelector = state => state.dashboard.readyForSendingList
export const totalSizeReadyForSendingSelector = state => state.dashboard.totalSizeReadyForSending
export const rejectedListSelector = state => state.dashboard.rejectedList
export const totalSizeRejectedSelector = state => state.dashboard.totalSizeRejected
export const undispatchedListSelector = state => state.dashboard.undispatchedList
export const totalSizeUndispatchedSelector = state => state.dashboard.totalSizeUndispatched

export const isLoadingSelector = state => state.dashboard.isLoading

export const { actions, reducer } = dashboardSlice
export default reducer
