import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { routes } from 'routes'
import { router } from '../../core/Root'
import { hide, open } from '../modal/slice'
import { addToast } from '../toast/slice'
import * as api from './api'
export const getAll = createAsyncThunk('folder/GET_ALL', async (tableState, thunkAPI) => {
  const { data, error } = await api.getAll(tableState)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const getAllNew = createAsyncThunk('folder/GET_ALL_NEW', async (tableState, thunkAPI) => {
  const { data, error } = await api.getMyActivities(tableState)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const getAllReadyForControl = createAsyncThunk(
  'folder/GET_ALL_READY_FOR_CONTROL',
  async (tableState, thunkAPI) => {
    const { data, error } = await api.getMyActivities(tableState)
    if (error) return thunkAPI.rejectWithValue(error)
    else {
      return data
    }
  },
)

export const getAllReadyForSending = createAsyncThunk(
  'folder/GET_ALL_READY_FOR_SENDING',
  async (tableState, thunkAPI) => {
    const { data, error } = await api.getMyActivities(tableState)
    if (error) return thunkAPI.rejectWithValue(error)
    else {
      return data
    }
  },
)

export const getAllRejected = createAsyncThunk('folder/GET_ALL_REJECTED', async (tableState, thunkAPI) => {
  const { data, error } = await api.getMyActivities(tableState)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const getAllSent = createAsyncThunk('folder/GET_ALL_SENT', async (tableState, thunkAPI) => {
  const { data, error } = await api.getMyActivities(tableState)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const getAllUndispatched = createAsyncThunk('folder/GET_ALL_UNDISPATCHED', async (tableState, thunkAPI) => {
  const { data, error } = await api.getUndipatched(tableState)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const get = createAsyncThunk('folder/GET', async (id, thunkAPI) => {
  const { data, error } = await api.get(id)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    // thunkAPI.dispatch(getNavigation({ currentFolderId: id, }))
    return data
  }
})

export const getNavigation = createAsyncThunk('folder/GET_NAVIGATION', async (request, thunkAPI) => {
  const { data, error } = await api.getNavigation(request)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const create = createAsyncThunk('folder/CREATE', async ({ values, modal }, thunkAPI) => {
  const { data, error } = await api.create(values)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    thunkAPI.dispatch(hide(modal))
    router.navigate(routes.folderDetails.path.replace(':id', data.id))
    return data
  }
})

export const update = createAsyncThunk('folder/UPDATE', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.update({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) {
    thunkAPI.dispatch(addToast({ type: 'failure', message: error.errorCode }))
    return thunkAPI.rejectWithValue(error)
  } else {
    thunkAPI.dispatch(addToast({ type: 'success', message: 'customerHasSet' }))
    thunkAPI.dispatch(get(values.id))
    return data
  }
})

export const getAllStatuses = createAsyncThunk('folder/GET_ALL_STATUSES', async (request, thunkAPI) => {
  const { data, error } = await api.getAllStatuses()
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    // thunkAPI.dispatch(get(values.id))
    return data
  }
})

export const massJobs = createAsyncThunk('folder/MASS_JOBS', async ({ values }, thunkAPI) => {
  const state = thunkAPI.getState()

  const { data, error } = await api.massJobs({
    ...values,
    actionToken: state.folder.actionToken,
    dateOfList: state.folder.dateOfList,
  })
  if (error) {
    thunkAPI.dispatch(addToast({ type: 'failure', message: error.errorCode }))
    return thunkAPI.rejectWithValue(error)
  } else {
    thunkAPI.dispatch(addToast({ type: 'success', message: 'MassJobStarted' }))
    return data
  }
})

export const validateMassJobs = createAsyncThunk(
  'folder/VALIDATE_MASS_JOBS',
  async ({ values, modalName }, { getState, dispatch, rejectWithValue }) => {
    const state = getState()
    const { data, error } = await api.validate({
      ...values,
      actionToken: state.folder.actionToken,
      dateOfList: state.folder.dateOfList,
    })
    if (error || data?.isValid === false) {
      dispatch(open(modalName))

      return rejectWithValue(error)
    } else {
      dispatch(massJobs({ values }))
      dispatch(clearSelections())
      return data
    }
  },
)

export const getFoldersCount = createAsyncThunk('folder/GET_FOLDERS_COUNT', async thunkAPI => {
  const { data, error } = await api.getFoldersCountByStatus()
  if (error) {
    return thunkAPI.rejectWithValue(error)
  } else {
    return data
  }
})

const initialState = {
  list: [],
  listNew: [],
  readyForSendingList: [],
  readyForControlList: [],
  listRejected: [],
  listSent: [],
  totalSize: 0,
  totalSizeNew: 0,
  totalSizeReadyForControl: 0,
  totalSizeReadyForSending: 0,
  totalSizeRejected: 0,
  totalSizeSent: 0,
  isListLoading: false,
  isListNewLoading: false,
  isListReadyForControlLoading: false,
  isListReadyForSendingLoading: false,
  isListRejectedLoading: false,
  isListSentLoading: false,
  model: null,
  isModelLoading: false,
  error: null,
  statusList: [],
  navigation: null,
  isNavigationLoading: false,
  isStatusListLoading: false,
  selections: [],
  massJobsError: null,
  isMassJobsLoading: false,
  everyDocumentIsSelected: false,
  foldersCountByStatus: null,
  foldersCountByStatusIsLoading: false,
  totalSizeUndispatched: 0,
  undispatchedList: [],
  isUndispatchedListLoading: false,
  actionToken: '',
  dateOfList: '',
  isComposeEmailActive: false,
}
export const slice = createSlice({
  name: 'folder',
  initialState: initialState,
  reducers: {
    clearError: state => {
      state.error = null
    },
    resetState: () => {
      return { ...initialState }
    },
    updateSelections: (state, action) => {
      const { rowId, isSelect, statusId } = action.payload
      const selections = state.selections
      state.selections = isSelect ? [...selections, { rowId, statusId }] : selections.filter(s => s.rowId !== rowId)
    },
    setEveryDocumentIsSelected: (state, action) => {
      state.everyDocumentIsSelected = action.payload
    },
    clearSelections: state => {
      state.selections = []
      state.everyDocumentIsSelected = false
    },
    clearList: state => {
      state.list = []
    },
    clearFoldersCount: state => {
      state.foldersCountByStatus = []
    },
    setIsComposeEmailActive: (state, action) => {
      state.isComposeEmailActive = action.payload
    },
  },
  extraReducers: builder => [
    builder
      .addCase(getAll.pending, state => {
        state.isListLoading = true
        state.error = null
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isListLoading = false
        state.list = action.payload.data
        state.totalSize = action.payload.totalCount
        state.actionToken = action.payload.actionToken
        state.dateOfList = action.payload.dateOfList
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isListLoading = false
        state.error = action.error
      })

      .addCase(getAllNew.pending, state => {
        state.isListNewLoading = true
        state.isListLoading = true
        state.error = null
      })
      .addCase(getAllNew.fulfilled, (state, action) => {
        state.isListNewLoading = false
        state.isListLoading = false
        state.listNew = action.payload.data
        state.totalSizeNew = action.payload.totalCount
        state.actionToken = action.payload.actionToken
        state.dateOfList = action.payload.dateOfList
      })
      .addCase(getAllNew.rejected, (state, action) => {
        state.isListNewLoading = false
        state.isListLoading = false
        state.error = action.error
      })
      .addCase(getAllReadyForControl.pending, state => {
        state.isListReadyForControlLoading = true
        state.isListLoading = true
        state.error = null
      })
      .addCase(getAllReadyForControl.fulfilled, (state, action) => {
        state.isListReadyForControlLoading = false
        state.isListLoading = false
        state.readyForControlList = action.payload.data
        state.totalSizeReadyForControl = action.payload.totalCount
        state.actionToken = action.payload.actionToken
        state.dateOfList = action.payload.dateOfList
      })
      .addCase(getAllReadyForControl.rejected, (state, action) => {
        state.isListReadyForControlLoading = false
        state.isListLoading = false
        state.error = action.error
      })
      .addCase(getAllReadyForSending.pending, state => {
        state.isListReadyForSendingLoading = true
        state.isListLoading = true
        state.error = null
      })
      .addCase(getAllReadyForSending.fulfilled, (state, action) => {
        state.isListReadyForSendingLoading = false
        state.isListLoading = false
        state.readyForSendingList = action.payload.data
        state.totalSizeReadyForSending = action.payload.totalCount
        state.actionToken = action.payload.actionToken
        state.dateOfList = action.payload.dateOfList
      })
      .addCase(getAllReadyForSending.rejected, (state, action) => {
        state.isListLoading = false
        state.isListLoading = false
        state.error = action.error
      })
      .addCase(getAllRejected.pending, state => {
        state.isListRejectedLoading = true
        state.isListLoading = true
        state.error = null
      })
      .addCase(getAllRejected.fulfilled, (state, action) => {
        state.isListRejectedLoading = false
        state.isListLoading = false
        state.listRejected = action.payload.data
        state.totalSizeRejected = action.payload.totalCount
        state.actionToken = action.payload.actionToken
        state.dateOfList = action.payload.dateOfList
      })
      .addCase(getAllRejected.rejected, (state, action) => {
        state.isListRejectedLoading = false
        state.isListLoading = false
        state.error = action.error
      })
      .addCase(getAllSent.pending, state => {
        state.isListSentLoading = true
        state.isListLoading = true
        state.error = null
      })
      .addCase(getAllSent.fulfilled, (state, action) => {
        state.isListSentLoading = false
        state.isListLoading = false
        state.listSent = action.payload.data
        state.totalSizeSent = action.payload.totalCount
        state.actionToken = action.payload.actionToken
        state.dateOfList = action.payload.dateOfList
      })
      .addCase(getAllSent.rejected, (state, action) => {
        state.isListSentLoading = false
        state.isListLoading = false
        state.error = action.error
      })
      .addCase(get.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
        state.actionToken = action.payload.actionToken
      })
      .addCase(get.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.payload
      })

      .addCase(getNavigation.pending, state => {
        state.isModelLoading = true
        state.isNavigationLoading = true
        state.error = null
      })
      .addCase(getNavigation.fulfilled, (state, action) => {
        state.isNavigationLoading = false
        state.navigation = action.payload
      })
      .addCase(getNavigation.rejected, (state, action) => {
        state.isNavigationLoading = false
        state.navigation = null
        state.error = action.error
      })

      .addCase(create.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(create.fulfilled, state => {
        state.isModelLoading = false
      })
      .addCase(create.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      })

      .addCase(update.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(update.fulfilled, state => {
        state.isModelLoading = false
      })
      .addCase(update.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      })

      .addCase(getAllStatuses.pending, state => {
        state.isStatusListLoading = true
        state.error = null
      })
      .addCase(getAllStatuses.fulfilled, (state, action) => {
        state.isStatusListLoading = false
        state.statusList = action.payload
      })
      .addCase(massJobs.pending, state => {
        state.isMassJobsLoading = true
        state.massJobsError = null
      })
      .addCase(massJobs.fulfilled, state => {
        state.isStatusListLoading = false
        state.massJobsError = null
      })
      .addCase(massJobs.rejected, (state, action) => {
        state.isStatusListLoading = false
        state.massJobsError = action.error
      })
      .addCase(getFoldersCount.pending, state => {
        state.foldersCountByStatusIsLoading = true
      })
      .addCase(getFoldersCount.fulfilled, (state, action) => {
        state.foldersCountByStatusIsLoading = false
        state.foldersCountByStatus = action.payload
      })
      .addCase(getFoldersCount.rejected, state => {
        state.foldersCountByStatusIsLoading = false
      })
      .addCase(getAllUndispatched.pending, state => {
        state.isUndispatchedListLoading = true
        state.isListLoading = true
        state.error = null
      })
      .addCase(getAllUndispatched.fulfilled, (state, action) => {
        state.isUndispatchedListLoading = false
        state.isListLoading = false
        state.undispatchedList = action.payload.data
        state.totalSizeUndispatched = action.payload.totalCount
        state.actionToken = action.payload.actionToken
        state.dateOfList = action.payload.dateOfList
      })
      .addCase(getAllUndispatched.rejected, (state, action) => {
        state.isUndispatchedListLoading = false
        state.isListLoading = false
        state.error = action.error
      }),
  ],
})

export const {
  clearError,
  resetState,
  updateSelections,
  clearSelections,
  setEveryDocumentIsSelected,
  clearList,
  clearFoldersCount,
  setIsComposeEmailActive,
} = slice.actions

export const modelSelector = state => state.folder.model
export const isModelLoadingSelector = state => state.folder.isModelLoading
export const listSelector = state => state.folder.list
export const isListLoadingSelector = state => state.folder.isListLoading
export const errorSelector = state => state.folder.error
export const totalSizeSelector = state => state.folder.totalSize
export const statusListSelector = state => state.folder.statusList
export const isStatusListLoadingSelector = state => state.folder.isStatusListLoading
export const navigationSelector = state => state.folder.navigation
export const isNavigationLoadingSelector = state => state.folder.isNavigationLoading
export const isMassJobsLoadingSelector = state => state.folder.isMassJobsLoading
export const massJobsErrorSelector = state => state.folder.massJobsError
export const everyDocumentIsSelectedSelcetor = state => state.folder.everyDocumentIsSelected
export const foldersCountByStatusSelector = state => state.folder.foldersCountByStatus
export const foldersCountByStatusIsLoadingSelector = state => state.folder.foldersCountByStatusIsLoading

export const totalSizeUndispatchedSelector = state => state.folder.totalSizeUndispatched
export const undispatchedListSelector = state => state.folder.undispatchedList
export const isUndispatchedListLoadingSelector = state => state.folder.isUndispatchedListLoading

export const selectionsSelector = state => state.folder.selections
export const newListSelector = state => state.folder.listNew
export const readyForControlListSelector = state => state.folder.readyForControlList
export const readyForSendingListSelector = state => state.folder.readyForSendingList
export const rejectedListSelector = state => state.folder.listRejected
export const sentListSelector = state => state.folder.listSent
export const totalSizeNewSelector = state => state.folder.totalSizeNew
export const totalSizeReadyForControlSelector = state => state.folder.totalSizeReadyForControl
export const totalSizeReadyForSendingSelector = state => state.folder.totalSizeReadyForSending
export const totalSizeRejectedSelector = state => state.folder.totalSizeRejected
export const totalSizeSentSelector = state => state.folder.totalSizeSent
export const isListNewLoadingSelector = state => state.folder.isListNewLoading
export const isListReadyForControlLoadingSelector = state => state.folder.isListReadyForControlLoading
export const isListReadyForSendingLoadingSelector = state => state.folder.isListReadyForSendingLoading
export const isListRejectedLoadingSelector = state => state.folder.isListRejectedLoading

export const isComposeEmailActiveSelector = state => state.folder.isComposeEmailActive

export const reducer = slice.reducer
