import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get as getFolder, setIsComposeEmailActive } from '../folder/slice'
import { addToast } from '../toast/slice'
import * as api from './api'

export const sendEmail = createAsyncThunk('email/SEND_EMAIL', async (payload, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.sendEmail({
    ...payload,
    actionToken: state.folder.actionToken,
  })
  thunkAPI.dispatch(addToast({ type: 'info', message: 'sendingInProgress' }))
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    thunkAPI.dispatch(slice.actions.clearCompose())
    // thunkAPI.dispatch(getAll({ folderId: payload.folderId }))
    thunkAPI.dispatch(getFolder(payload.folderId))
    thunkAPI.dispatch(setIsComposeEmailActive(false))
    return data
  }
})

export const getEmailInfo = createAsyncThunk('email/GET_EMAIL_INFO', async (guid, thunkAPI) => {
  const { data, error } = await api.getEmailInfo(guid)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const getAll = createAsyncThunk('email/GET_ALL', async ({ folderId }, thunkAPI) => {
  const { data, error } = await api.getAll(folderId)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const getEmailMessage = createAsyncThunk('email/GET_EMAIL_MESSAGE', async ({ id }, thunkAPI) => {
  const { data, error } = await api.getEmailMessage(id)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const getAllStatuses = createAsyncThunk('email/GET_ALL_STATUSES', async (payload, thunkAPI) => {
  const result = await api.getAllStatuses()
  if (result.error) return thunkAPI.rejectWithValue(result.error)
  return result.data
})

export const getPopulated = createAsyncThunk('email/GET_POPULATED', async ({ folderId, templateId }, thunkAPI) => {
  const { data, error } = await api.getPopulated(folderId, templateId)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const slice = createSlice({
  name: 'email',
  initialState: {
    error: null,
    model: null,
    isModelLoading: false,
    emailInfo: null,
    list: [],
    isListLoading: false,
    isSending: false,
    isSent: false,
    statusList: [],
    isStatusListLoading: false,
    isPopulatedLoading: false,
    composeModel: null,
  },
  reducers: {
    clearError: state => {
      state.error = null
    },
    clearCompose: state => {
      state.composeModel = null
    },
    clearMessages: state => {
      state.isSent = false
    },
  },
  extraReducers: builder => [
    builder
      .addCase(sendEmail.pending, (state, action) => {
        state.error = null
        state.isSending = true
        state.isSent = false
        state.composeModel = action.meta.arg
      })
      .addCase(sendEmail.fulfilled, state => {
        //
        state.isSending = false
        state.isSent = true
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.isSending = false
        state.isSent = false
        state.error = action.payload
      })

      .addCase(getEmailInfo.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(getEmailInfo.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.emailInfo = action.payload
      })
      .addCase(getEmailInfo.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      })

      .addCase(getAll.pending, (state, action) => {
        // state.isListLoading = action.shouldLoad
        state.isListLoading = action.meta.arg.shouldLoad
        state.error = null
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isListLoading = false
        // state.list = action.payload.data
        state.list = action.payload
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isListLoading = false
        state.error = action.error
      })

      .addCase(getEmailMessage.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(getEmailMessage.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
      })
      .addCase(getEmailMessage.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      })

      .addCase(getAllStatuses.pending, state => {
        state.isStatusListLoading = true
        state.error = null
      })
      .addCase(getAllStatuses.fulfilled, (state, action) => {
        state.isStatusListLoading = false
        state.statusList = action.payload
      })
      .addCase(getAllStatuses.rejected, (state, action) => {
        state.isStatusListLoading = false
        state.error = action.error
      })

      .addCase(getPopulated.pending, (state, action) => {
        state.isPopulatedLoading = true
        state.error = null
        // state.composeModel = action.payload.values
        state.composeModel = action.meta.arg
      })
      .addCase(getPopulated.fulfilled, (state, action) => {
        state.isPopulatedLoading = false
        state.composeModel = {
          ...state.composeModel,
          subject: action.payload.subject,
          content: action.payload.content,
          sendFilesAsAttachments: action.payload.sendFilesAsAttachments,
        }
      })
      .addCase(getPopulated.rejected, (state, action) => {
        state.isPopulatedLoading = false
        state.error = action.error
      }),
  ],
})

export const { clearError, clearCompose, clearMessages } = slice.actions

export const modelSelector = state => state.email.model
export const isModelLoadingSelector = state => state.email.isModelLoading
export const listSelector = state => state.email.list
export const isListLoadingSelector = state => state.email.isListLoading
export const errorSelector = state => state.email.error
export const composeModelSelector = state => state.email.composeModel
export const isSentSelector = state => state.email.isSent
export const isSendingSelector = state => state.email.isSending
export const emailInfoSelector = state => state.email.emailInfo
export const statusListSelector = state => state.email.statusList
export const isStatusListLoadingSelector = state => state.email.isStatusListLoading

export const reducer = slice.reducer
