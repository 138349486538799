import { ErrorPage } from 'components/AlertMessage'
import DelayedLoadingView from 'components/DelayedLoadingView'
import { Suspense } from 'react'
import { useOutletContext } from 'react-router-dom'

import { t } from 'i18n/translation'
import { isAuthorized } from './AuthorizeService'

const RenderRoute = props => {
  const { component: Component, roles } = props
  const { userRoles } = useOutletContext()
  const isAuthorize = userRoles && isAuthorized(userRoles, roles)

  return userRoles ? (
    !isAuthorize ? (
      <ErrorPage message={t('errorCode.NotAuthorized')} />
    ) : (
      <Suspense fallback={<DelayedLoadingView />}>
        <Component userRoles={userRoles} />
      </Suspense>
    )
  ) : (
    <></>
  )
}

export default RenderRoute
