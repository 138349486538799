import React from 'react'
import { useFormField } from '../useFormField'

export const Select = allProps => {
  const {
    props,
    label,
    name,
    labelClasses,
    fieldError,
    className,
    formGroupClasses,
    fromLibraries: { Field, classnames },
  } = useFormField(allProps)
  const { options, keyProp, text, emptyText, ...rest } = props

  return (
    <div className={classnames('form-group', formGroupClasses)}>
      {label && (
        <label htmlFor={name} className={labelClasses}>
          {label}
        </label>
      )}
      <Field
        component="select"
        id={name}
        {...rest}
        className={classnames('form-control', fieldError && 'is-invalid', className)}
      >
        {emptyText && (
          <option key="" value={''}>
            {emptyText}
          </option>
        )}
        {options &&
          options.map((x, i) => (
            <option key={i} value={x[keyProp]}>
              {x[text]}
            </option>
          ))}
      </Field>
      {fieldError && <small className="form-text text-danger">{fieldError}</small>}
    </div>
  )
}
