import { get, post } from 'api'

export const sendEmail = async ({ receivers, cc, CCSelections, assignToUserId, ...values }) => {
  return post('/emailmessage/sendtoemail', {
    customerContactIdList: receivers,
    ccList: Array.isArray(cc) ? CCSelections.filter(o => cc.includes(o.id)).map(x => x.email) : [],
    ...(assignToUserId && { assignToUserId }),
    ...values,
  })
}

export const getEmailInfo = async guid =>
  get(
    `/emailMessage/getEmailInfo`,
    { guid },
    {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
    },
  )

export const getAll = async id => get('/emailMessage/getEmailMessagesForFolder', { folderId: id })

export const getEmailMessage = async id => get('/emailMessage/getEmailMessage', { emailMessageId: id })

export const getAllStatuses = async () => get('/emailMessage/getallstatuses')

export const getPopulated = async (folderId, templateId) =>
  get(`/template/getPopulated`, {
    folderId,
    templateId,
  })
