import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'modal',
  initialState: {},
  reducers: {
    open: (state, { payload }) => {
      state[payload] = {
        show: true,
      }
    },

    hide: (state, { payload }) => {
      state[payload] = {
        show: false,
      }
    },
    openConfirmDialog: (state, { payload }) => {
      const { name, model } = payload
      state[name] = {
        show: true,
        model,
      }
    },
  },
})

export const { open, hide, openConfirmDialog } = slice.actions

export const modelSelctor = state => state.modal

export const reducer = slice.reducer
