import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as api from './api'
import { addToast } from '../toast/slice'

export const sendToContactSupport = createAsyncThunk('help/SEND_TO_CONTACT_SUPPORT', async (values, thunkAPI) => {
  const { data, error } = await api.sendToContactSupport(values)
  if (error) {
    thunkAPI.dispatch(addToast({ type: 'failure', message: error.errorCode }))
    return thunkAPI.rejectWithValue(error)
  } else {
    thunkAPI.dispatch(addToast({ type: 'success', message: 'yourMessageHasBeenSent' }))
    return data
  }
})

export const slice = createSlice({
  name: 'help',
  initialState: {
    model: null,
    isModelLoading: false,
    error: null,
    isSent: false,
  },
  reducers: {
    clearError: state => {
      state.error = null
    },
    clearMessages: state => {
      state.isSent = false
    },
  },
  extraReducers: builder => [
    builder
      .addCase(sendToContactSupport.pending, state => {
        state.isModelLoading = true
        state.isSent = false
        state.error = null
      })
      .addCase(sendToContactSupport.fulfilled, state => {
        state.isModelLoading = false
        state.isSent = true
      })
      .addCase(sendToContactSupport.rejected, (state, action) => {
        state.isModelLoading = false
        state.isSent = false
        state.error = action.error
      }),
  ],
})

export const { clearError, clearMessages } = slice.actions

export const modelSelector = state => state.help.model
export const isModelLoadingSelector = state => state.help.isModelLoading
export const errorSelector = state => state.help.error
export const isSentSelector = state => state.help.isSent

export const reducer = slice.reducer
