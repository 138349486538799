import { get as apiGet, post } from 'api'

export const getAll = async ({ status, sefStatus, crfStatus, documentType, ...request }) =>
  apiGet('/folder/getlist', {
    statusList: status,
    sefStatusList: sefStatus,
    crfStatusList: crfStatus,
    documentTypeList: documentType,
    ...request,
  })

export const getMyActivities = async ({ status, sefStatus, crfStatus, documentType, ...request }) =>
  apiGet('/myActivities/byStatus', {
    status,
    sefStatusList: sefStatus,
    crfStatusList: crfStatus,
    documentTypeList: documentType,
    ...request,
  })

export const getUndipatched = async tableState => apiGet('/myActivities/undispatched', tableState)

export const get = async id => apiGet(`/folder/${id}`)

export const getNavigation = async ({
  status,
  sefStatus,
  crfStatus,
  documentType,
  myActivitiesFolderStatus,
  ...request
}) => {
  return apiGet(`/folder/getNavigation`, {
    status: myActivitiesFolderStatus,
    statusList: status,
    sefStatusList: sefStatus,
    crfStatusList: crfStatus,
    documentTypeList: documentType,
    ...request,
  })
}

export const create = async ({ archiverId, ...values }) =>
  post('/folder/create', {
    archiverInvoiceId: archiverId,
    ...values,
  })

export const update = async values => post('/folder/setCustomer', values)
export const massJobs = async values => post('/massjobs', values)
export const validate = async values => post('/massjobs/validate', values)

export const getAllStatuses = async () => apiGet('/folder/getallstatuses')

export const getFoldersCountByStatus = async () => apiGet('/myActivities/countsByStatus')
