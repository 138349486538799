import { post, get as apiGet } from 'api'

export const get = async id => apiGet(`/ApprovalFlow/${id}`)
export const getAll = async tableState => apiGet('/ApprovalFlow', tableState)
export const add = async values => post('/ApprovalFlow', values)
export const deleteItem = async id => post(`/ApprovalFlow/${id}/delete`)
export const toggleFlowEnabled = async id => apiGet(`/ApprovalFlow/${id}/toggleEnabled`)

// export const getTrigger = async id => apiGet(`/ApprovalFlow/triggers/${id}`)
// export const getAllTrigger = async tableState => apiGet('/ApprovalFlow/triggers/', tableState)

// export const addTrigger = async values => post('/ApprovalFlow/triggers/', values)
// export const deleteTriggerItem = async id => post(`/ApprovalFlow/triggers/${id}/delete`)
// export const toggleTriggerEnabled = async id => apiGet(`/ApprovalFlow/triggers/${id}/toggleEnabled`)

export const getCompanyDocumentTypes = async () => apiGet('/Company/GetDocumentTypes')
